import { z } from 'zod'
import dayjs, { type Dayjs } from 'dayjs'
import {
	CALENDAR_EVENT_TYPE,
	RESERVATION_STATE,
	SALONS_TAB_KEYS,
	SALON_CREATE_TYPE,
	SALON_FILTER_OPENING_HOURS,
	SALON_FILTER_RS,
	SALON_FILTER_RS_AVAILABLE_ONLINE,
	SALON_FILTER_STATES,
	SALON_SOURCE_TYPE,
	CALENDAR_VIEW,
	CALENDAR_DATE_FORMAT,
	RESERVATION_PAYMENT_METHOD,
	RESERVATION_SOURCE_TYPE,
	RESERVATIONS_STATE,
	ACCOUNT_STATE,
	EMPLOYEES_TAB_KEYS,
	REVIEW_VERIFICATION_STATUS,
	REVIEWS_TAB_KEYS,
	DEFAULT_DATE_INIT_FORMAT,
	CALENDAR_EVENT_TYPE_REQUEST,
	ASSET_TYPE,
	NOTIFICATION_EVENT_GROUP_TYPE,
	RESERVATIONS_DATE_TIME_FRAME,
	CHANGELOG_PLATFORM,
	UPCOMING_RESERVATIONS,
	VOUCHER_STATUS,
	WITHOUT_ASSIGNED_USER,
	CUSTOM_SERVICE_CHECKED_STATE,
	SALON_PREMIUM_SOURCE_TYPE,
	VALIDATION_MAX_LENGTH,
	SALON_RESERVATIONS_ORDER,
	ADMIN_RESERVATIONS_ORDER,
	CUSTOM_SERVICES_ORDER,
	SALONS_ORDER
} from '../utils/enums'
import { dateConstraint, stringConstraint, twoCharsConstraint, uuidConstraint } from './baseSchema'

const paginationSchema = z.object({
	page: z.number().optional(),
	limit: z.number().optional(),
	order: z.string().optional()
})

export const searchableSchema = paginationSchema.extend({
	search: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255).nullish()
})

/**
 * Salons
 */
// actions query params
export const salonsQueryParamsSchema = searchableSchema.extend({
	categoryFirstLevelIDs: uuidConstraint.array().nullish(),
	categoryThirdLevelIDs: uuidConstraint.array().nullish(),
	statuses_all: z.boolean().nullish(),
	statuses_published: z.enum([SALON_FILTER_STATES.PUBLISHED, SALON_FILTER_STATES.NOT_PUBLISHED]).nullish(),
	statuses_changes: z.enum([SALON_FILTER_STATES.PENDING_PUBLICATION, SALON_FILTER_STATES.DECLINED]).nullish(),
	countryCode: twoCharsConstraint.nullish(),
	createType: z.nativeEnum(SALON_CREATE_TYPE).nullish(),
	lastUpdatedAtFrom: z.string().nullish(),
	lastUpdatedAtTo: z.string().nullish(),
	hasSetOpeningHours: z.nativeEnum(SALON_FILTER_OPENING_HOURS).nullish(),
	sourceType: z.nativeEnum(SALON_SOURCE_TYPE).nullish(),
	assignedUserID: z.union([uuidConstraint, z.literal(WITHOUT_ASSIGNED_USER)]).nullish(),
	premiumSourceUserType: z.nativeEnum(SALON_PREMIUM_SOURCE_TYPE).nullish(),
	hasAvailableReservationSystem: z.nativeEnum(SALON_FILTER_RS_AVAILABLE_ONLINE).nullish(),
	enabledReservationsSetting: z.nativeEnum(SALON_FILTER_RS).nullish(),
	walletAvailableBalanceFrom: z.number().nullish(),
	walletAvailableBalanceTo: z.number().nullish(),
	salonState: z.nativeEnum(SALONS_TAB_KEYS).nullish(),
	hasUpcomingReservations: z.nativeEnum(UPCOMING_RESERVATIONS).nullish()
})

export const salonsToCheckQueryParamsSchema = searchableSchema.extend({
	statuses_all: z.boolean().nullish(),
	statuses_published: z.enum([SALON_FILTER_STATES.PUBLISHED, SALON_FILTER_STATES.NOT_PUBLISHED]).nullish(),
	statuses_changes: z.enum([SALON_FILTER_STATES.PENDING_PUBLICATION, SALON_FILTER_STATES.DECLINED]).nullish(),
	countryCode: twoCharsConstraint.nullish(),
	createType: z.nativeEnum(SALON_CREATE_TYPE).nullish(),
	assignedUserID: uuidConstraint.nullish()
})

export const salonHistoryQueryParamsSchema = paginationSchema.omit({ order: true }).extend({
	dateFrom: dateConstraint.catch(dayjs().subtract(1, 'week').format(DEFAULT_DATE_INIT_FORMAT)),
	dateTo: dateConstraint.catch(dayjs().format(DEFAULT_DATE_INIT_FORMAT)),
	salonID: uuidConstraint
})

// url query params
export const salonsActivePageURLQueryParamsSchema = salonsQueryParamsSchema
	.pick({
		page: true,
		limit: true,
		search: true,
		categoryFirstLevelIDs: true,
		categoryThirdLevelIDs: true,
		statuses_all: true,
		statuses_published: true,
		statuses_changes: true,
		countryCode: true,
		createType: true,
		lastUpdatedAtFrom: true,
		lastUpdatedAtTo: true,
		hasSetOpeningHours: true,
		sourceType: true,
		assignedUserID: true,
		premiumSourceUserType: true,
		hasAvailableReservationSystem: true,
		enabledReservationsSetting: true,
		hasUpcomingReservations: true
	})
	.extend({
		order: z.nativeEnum(SALONS_ORDER).optional().catch(SALONS_ORDER.CREATED_AT_DESC)
	})

export const salonsDeletedPageURLQueryParamsSchema = salonsQueryParamsSchema
	.pick({
		page: true,
		limit: true,
		search: true,
		categoryFirstLevelIDs: true,
		categoryThirdLevelIDs: true,
		countryCode: true
	})
	.extend({
		order: z.nativeEnum(SALONS_ORDER).optional().catch(SALONS_ORDER.CREATED_AT_DESC)
	})

export const salonsToCheckPageURLQueryParamsSchema = salonsToCheckQueryParamsSchema

export const salonsRejectedSuggestionsPageURLQueryParamsSchema = searchableSchema

export const rechargeSmsCreditAdminPageSchema = salonsQueryParamsSchema
	.pick({
		page: true,
		limit: true,
		order: true,
		search: true,
		countryCode: true,
		sourceType: true,
		walletAvailableBalanceFrom: true,
		walletAvailableBalanceTo: true
	})
	.extend({
		showForm: z.boolean().nullish()
	})

export const salonHistoryPageURLQueryParamsSchema = salonHistoryQueryParamsSchema.omit({ salonID: true })

/**
 * Calendar
 */
// actions query params
export const calendarEventsQueryParamsSchema = z.object({
	salonID: uuidConstraint,
	start: dateConstraint,
	end: dateConstraint,
	employeeIDs: z.string().array().nullish(),
	categoryIDs: z.string().array().nullish(),
	eventTypes: z.enum(CALENDAR_EVENT_TYPE_REQUEST).array().nullish(),
	reservationStates: z.nativeEnum(RESERVATION_STATE).array().nullish()
})

export const calendarReservationsQueryParamsSchema = calendarEventsQueryParamsSchema.omit({ reservationStates: true })

export const calendarShiftsTimeOffQueryParamsSchema = calendarEventsQueryParamsSchema.pick({ salonID: true, start: true, end: true, employeeIDs: true })

// url query params
export const calendarPageURLQueryParams = z.object({
	view: z.nativeEnum(CALENDAR_VIEW).catch(CALENDAR_VIEW.DAY),
	date: dateConstraint.catch(dayjs().format(CALENDAR_DATE_FORMAT.QUERY)),
	employeeIDs: uuidConstraint.array().nullish(),
	categoryIDs: z.string().array().nullish(),
	sidebarView: z.nativeEnum(CALENDAR_EVENT_TYPE).optional(),
	eventId: uuidConstraint.optional()
})

/**
 * List of reservations salon
 */
// actions query params
export const salonReservationsQueryParamsSchema = paginationSchema.omit({ order: true }).extend({
	dateFrom: dateConstraint.nullish(),
	dateTo: dateConstraint.nullish(),
	createdAtFrom: dateConstraint.nullish(),
	createdAtTo: dateConstraint.nullish(),
	employeeIDs: uuidConstraint.array().nullish(),
	categoryIDs: uuidConstraint.array().nullish(),
	reservationStates: z.nativeEnum(RESERVATION_STATE).array().nullish(),
	reservationCreateSourceType: z.nativeEnum(RESERVATION_SOURCE_TYPE).nullish(),
	reservationPaymentMethods: z.nativeEnum(RESERVATION_PAYMENT_METHOD).array().nullish(),
	salonID: uuidConstraint,
	customerIDs: uuidConstraint.array().nullish(),
	datetimeFrame: z.nativeEnum(RESERVATIONS_DATE_TIME_FRAME).nullish(),
	order: z.nativeEnum(SALON_RESERVATIONS_ORDER).optional().catch(SALON_RESERVATIONS_ORDER.CREATED_AT_DESC)
})

// url query params
export const salonReservationsPageURLQueryParams = salonReservationsQueryParamsSchema.omit({ salonID: true, datetimeFrame: true }).extend({
	state: z.nativeEnum(RESERVATIONS_STATE).catch(RESERVATIONS_STATE.ALL)
})

export const customerPageURLQueryParams = salonReservationsQueryParamsSchema
	.pick({
		page: true,
		limit: true,
		order: true
	})
	.extend({
		datetimeFrame: z.nativeEnum(RESERVATIONS_DATE_TIME_FRAME).catch(RESERVATIONS_DATE_TIME_FRAME.UPCOMING)
	})

export const employeePageURLQueryParams = salonReservationsQueryParamsSchema
	.pick({
		page: true,
		limit: true,
		order: true
	})
	.extend({
		datetimeFrame: z.nativeEnum(RESERVATIONS_DATE_TIME_FRAME).catch(RESERVATIONS_DATE_TIME_FRAME.UPCOMING)
	})

/**
 * List of reservations notino
 */
// actions query params
export const notinoReservationsQueryParamsSchema = searchableSchema.omit({ order: true }).extend({
	dateFrom: dateConstraint.nullish(),
	dateTo: dateConstraint.nullish(),
	createdAtFrom: dateConstraint.nullish(),
	createdAtTo: dateConstraint.nullish(),
	reservationStates: z.nativeEnum(RESERVATION_STATE).array().nullish(),
	reservationCreateSourceType: z.nativeEnum(RESERVATION_SOURCE_TYPE).nullish(),
	reservationPaymentMethods: z.nativeEnum(RESERVATION_PAYMENT_METHOD).array().nullish(),
	categoryFirstLevelIDs: uuidConstraint.array().nullish(),
	countryCode: twoCharsConstraint.nullish(),
	order: z.nativeEnum(ADMIN_RESERVATIONS_ORDER).optional().catch(ADMIN_RESERVATIONS_ORDER.CREATED_AT_DESC)
})

// url query params
export const notinoReservationsPageURLQueryParams = notinoReservationsQueryParamsSchema

/**
 * Employees
 */
// actions query params
export const employeesQueryParamsSchema = searchableSchema.extend({
	salonID: uuidConstraint.nullish(),
	serviceID: uuidConstraint.nullish(),
	accountState: z.nativeEnum(ACCOUNT_STATE).nullish()
})

export const employeeShiftsQueryParamsSchema = paginationSchema.omit({ order: true }).extend({
	employeeID: uuidConstraint
})

// url query params
export const employeesPageURLQueryParams = employeesQueryParamsSchema.omit({ salonID: true }).extend({
	employeeState: z.nativeEnum(EMPLOYEES_TAB_KEYS).catch(EMPLOYEES_TAB_KEYS.ACTIVE)
})

/**
 * Customers
 */
// actions query params
export const customersQueryParamsSchema = searchableSchema.extend({
	salonID: uuidConstraint.nullish()
})

// url query params
export const customersPageURLQueryParams = customersQueryParamsSchema.omit({ salonID: true })

/**
 * Salon services
 */
// actions query params
export const servicesQueryParamsSchema = z.object({
	salonID: uuidConstraint,
	rootCategoryID: uuidConstraint.nullish()
})

// url query params
export const servicesPageURLQueryParams = servicesQueryParamsSchema.omit({ salonID: true })

/**
 * Support contacts
 */
// actions query params
export const supportContactsQueryParamsSchema = z.object({
	countryCode: twoCharsConstraint.nullish()
})

// url query params
export const supportContactsPageURLQueryParams = z.object({
	search: z.string().nullish(),
	order: z.string().nullish()
})

/**
 * Languages
 */
// url query params
export const languagesPageURLQueryParams = z.object({
	search: z.string().nullish(),
	order: z.string().nullish()
})

/**
 * Cosmetics
 */
// actions query params
export const cosmeticsQueryParamsSchema = searchableSchema.omit({ order: true })

// url query params
export const cosmeticsPageURLQueryParams = cosmeticsQueryParamsSchema

/**
 * Category params
 */
// url query params
export const categoryParamsPageURLQueryParams = z.object({
	search: z.string().nullish(),
	order: z.string().nullish()
})

/**
 * Users
 */
// actions query params
export const usersQueryParamsSchema = searchableSchema.extend({
	roleID: uuidConstraint.nullish()
})

// url query params
export const usersPageURLQueryParams = usersQueryParamsSchema

/**
 * Notifications
 */
// actions query params
export const userNotificationsQueryParamsSchema = z.object({
	userID: uuidConstraint,
	page: z.number().optional(),
	limit: z.number().optional(),
	onlyUnread: z.boolean().optional(),
	notificationEventGroupType: z.nativeEnum(NOTIFICATION_EVENT_GROUP_TYPE)
})

/**
 * Create password route
 */
// url query params
export const createPasswordPageURLQueryParamsSchema = z.object({
	t: z.string().nullish()
})

/**
 * Sms credits
 */
// actions query params
export const smsHistoryQueryParamsSchema = searchableSchema.extend({
	salonID: uuidConstraint,
	dateFrom: dateConstraint.nullish(),
	dateTo: dateConstraint.nullish()
})

export const smsUnitPricesParamsSchema = searchableSchema.omit({ search: true }).extend({
	countryCode: twoCharsConstraint
})

// page query params
export const smsCreditPartnerPageQueryParams = smsHistoryQueryParamsSchema.omit({ salonID: true, dateFrom: true, dateTo: true }).extend({
	date: z.instanceof(dayjs as unknown as typeof Dayjs)
})

export const smsUnitPricesDetailPageQueryParams = searchableSchema.omit({ search: true })

/**
 * Reviews
 */
// actions query params
export const reviewsQueryParamsSchema = searchableSchema.extend({
	verificationStatus: z.nativeEnum(REVIEW_VERIFICATION_STATUS).nullish(),
	deleted: z.boolean().optional(),
	salonCountryCode: twoCharsConstraint.nullish(),
	toxicityScoreFrom: z.number().optional(),
	toxicityScoreTo: z.number().optional()
})

// url query params
export const reviewsPageURLQueryParams = reviewsQueryParamsSchema.omit({ deleted: true }).extend({
	reviewState: z.nativeEnum(REVIEWS_TAB_KEYS).catch(REVIEWS_TAB_KEYS.PUBLISHED)
})

/**
 * Specialist contacts
 */
export const specialistContactsPageURLQueryParams = z.object({
	search: z.string().nullish(),
	order: z.string().nullish()
})

/**
 * Documents
 */

export const documentsPageQueryParams = paginationSchema.extend({
	languageCode: twoCharsConstraint.optional(),
	assetTypes: z.nativeEnum(ASSET_TYPE).array().optional()
})

export const documentsGroupedByAssetTypesQueryParams = paginationSchema.omit({ order: true })

export const documentsPageURLQueryParamsSchema = documentsGroupedByAssetTypesQueryParams

/**
 * Documents by asset types
 */

export const documentsAssetTypesRequestQueryParams = paginationSchema.extend({
	languageCode: twoCharsConstraint,
	assetType: z.nativeEnum(ASSET_TYPE)
})

export const documentsAssetTypesPageURLQueryParamsSchema = documentsAssetTypesRequestQueryParams.omit({ assetType: true })

/**
 * MSredirect
 */
export const msRedirectPageURLQueryParams = z.object({
	code: z.string().nullish(),
	state: z.string().default('').catch('')
})

/**
 * Dynamic notifications
 */
// actions query params
export const dynamicNotificationsQueryParamsSchema = paginationSchema.extend({
	language: twoCharsConstraint.nullish()
})

// url query params
export const dynamicNotificationsPageURLQueryParams = dynamicNotificationsQueryParamsSchema

/**
 * Transactions
 */
// actions query params
export const walletTransactionsSchema = paginationSchema.extend({
	walletID: uuidConstraint
})

/**
 * Vouchers
 */
// actions query params
export const vouchersQueryParamsSchema = paginationSchema.extend({
	salonID: uuidConstraint,
	status: z.nativeEnum(VOUCHER_STATUS).nullish()
})

/**
 * Changelogs
 */
// actions query params
export const changelogsQueryParamsSchema = searchableSchema.extend({
	platform: z.nativeEnum(CHANGELOG_PLATFORM).nullish()
})

// url query params
export const changelogsPageURLQueryParams = changelogsQueryParamsSchema

/**
 * Salon photoalbums
 */
// actions query params
export const salonPhotoalbumsQueryParamsSchema = searchableSchema.extend({
	salonID: uuidConstraint
})

export const salonPhotoalbumQueryParamsSchema = z.object({
	salonID: uuidConstraint,
	salonPhotoAlbumID: uuidConstraint
})

/**
 * Custom services
 */
// actions query params
export const customServicesQueryParamsSchema = searchableSchema.omit({ order: true }).extend({
	countryCode: twoCharsConstraint.nullish(),
	categoryIDs: uuidConstraint.array().nullish(),
	serviceCreatedAtFrom: dateConstraint.nullish(),
	serviceCreatedAtTo: dateConstraint.nullish(),
	checkedState: z.nativeEnum(CUSTOM_SERVICE_CHECKED_STATE).nullish(),
	order: z.nativeEnum(CUSTOM_SERVICES_ORDER).optional().catch(CUSTOM_SERVICES_ORDER.CREATED_AT_DESC)
})

// url query params
export const customServicesPageURLQueryParams = customServicesQueryParamsSchema
