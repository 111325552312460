import {
	IIbanCalculatorForm,
	IActivationForm,
	IBillingForm,
	ICalendarEventForm,
	ICalendarReservationForm,
	ICategoryForm,
	ICategoryParamsForm,
	ICreatePasswordForm,
	ICustomerForm,
	IDocumentForm,
	IDynamicNotificationsForm,
	IDynamicNotificationsSettingsForm,
	IEditEmployeeRoleForm,
	IEditUserRoleForm,
	IEmployeeForm,
	IEmployeeServiceEditForm,
	IForgotPasswordForm,
	IIndustriesForm,
	IIndustryForm,
	IInviteEmployeeForm,
	ILanguageForm,
	ILoginForm,
	INoteForm,
	IOpenHoursNoteForm,
	IRechargeSmsCreditFilterForm,
	IRechargeSmsCreditForm,
	IRegistrationForm,
	IReviewFilterForm,
	ISalonForm,
	IServiceForm,
	ISmsUnitPricesForm,
	ISpecialistContactForm,
	ISupportContactForm,
	IUserAccountForm,
	IUserLanguageSelectionForm,
	IAccountSettingsForm,
	IAffiliateProgramSmsTransactionForm,
	ILoginAsPartnerForm,
	IEmployeeServicesForm,
	IChangelogForm,
	IAffiliateProgramVoucherTransactionForm,
	IEmployeeShiftsForm,
	IEmployeeShiftPlanningForm,
	IPhotoalbumForm,
	IPhotoDescriptionForm,
	IReservationSystemDisabledFeedbackForm,
	IServiceCustomForm,
	IImportCustomersForm,
	IImportReservationsForm,
	IImportSalonsForm,
	ICreateReservationFeedbackForm
} from '../types/interfaces'
import { FORM } from './enums'
import { activationSchema } from '../schemas/activation'
import { zodErrorsToFormErrors } from '../schemas/baseSchema'
import { billingSchema } from '../schemas/billing'
import { categorySchema } from '../schemas/category'
import { categoryParamsSchema } from '../schemas/categoryParams'
import { customerSchema } from '../schemas/customer'
import { documentSchema } from '../schemas/document'
import { employeeSchema, employeeServicesSchema, employeeShiftPlanningSchema, employeeShiftsSchema, inviteEmployeeSchema } from '../schemas/employee'
import { eventSchema } from '../schemas/event'
import { industriesSchema, industrySchema } from '../schemas/industry'
import { languageSchema } from '../schemas/language'
import { loginAsPartnerSchema, loginSchema } from '../schemas/login'
import { noteSchema } from '../schemas/note'
import { dynamicNotificationsSchema, dynamicNotificationsSettingSchema } from '../schemas/dynamicNotifications'
import { openHoursNoteSchema } from '../schemas/openHoursNote'
import { createPasswordSchema, forgotPasswordSchema } from '../schemas/password'
import { rechargeSmsCreditFilterSchema, rechargeSmsCreditSchema } from '../schemas/rechargeSmsCredit'
import { registrationSchema } from '../schemas/registration'
import { reservationsSchema } from '../schemas/reservation'
import { reviewSchema } from '../schemas/review'
import { employeeRoleSchema, userRoleSchema } from '../schemas/role'
import { salonSchema } from '../schemas/salon'
import { employeeServiceSchema, serviceCustomSchema, serviceSchema } from '../schemas/service'
import { smsUnitPricesSchema } from '../schemas/smsUnitPrices'
import { supportContactSchema } from '../schemas/supportContact'
import { specialistSchema } from '../schemas/specialist'
import { createUserSchema, editUserSchema } from '../schemas/user'
import { userLanguageSelectionSchema } from '../schemas/userLanguageSelection'
import { importCustomers, importReservations, importSalons } from '../schemas/asyncJobs'
import { accountSettingsSchema } from '../schemas/accountSettings'
import { affiliateProgramSmsTransactionSchema, affiliateProgramVoucherTransactionSchema } from '../schemas/affiliateProgram'
import { ibanCalculatorSchema } from '../schemas/ibanCalculator'
import { changelogSchema } from '../schemas/changelog'
import { photoalbumSchema } from '../schemas/photoalbum'
import { photoDescriptionSchema } from '../schemas/photoDescription'
import { reservationSystemDisabledFeedbackSchema } from '../schemas/reservationSystemDisabledFeedback'
import { createReservationFeedbackSchema } from '../schemas/createReservationFeedback'

export const validationActivationFn = (values: IActivationForm, props: any) => zodErrorsToFormErrors(activationSchema, FORM.ACTIVATION, values, props)
export const validationBillingInfoFn = (values: IBillingForm, props: any) => zodErrorsToFormErrors(billingSchema, FORM.SALON_BILLING_INFO, values, props)
export const validationCategoryFn = (values: ICategoryForm, props: any) => zodErrorsToFormErrors(categorySchema, FORM.CATEGORY, values, props)
export const validationCategoryParamsFn = (values: ICategoryParamsForm, props: any) => zodErrorsToFormErrors(categoryParamsSchema, FORM.CATEGORY_PARAMS, values, props)
export const validationCustomerFn = (values: ICustomerForm, props: any) => zodErrorsToFormErrors(customerSchema, FORM.CUSTOMER, values, props)
export const validationDocumentFn = (values: IDocumentForm, props: any) => zodErrorsToFormErrors(documentSchema, FORM.DOCUMENTS_FORM, values, props)
export const validationEmployeeFn = (values: IEmployeeForm, props: any) => zodErrorsToFormErrors(employeeSchema, FORM.EMPLOYEE, values, props)
export const validationEmployeeServicesFn = (values: IEmployeeServicesForm, props: any) => zodErrorsToFormErrors(employeeServicesSchema, FORM.EMPLOYEE_SERVICES, values, props)
export const validationInviteEmployeeFn = (values: IInviteEmployeeForm, props: any) => zodErrorsToFormErrors(inviteEmployeeSchema, FORM.INVITE_EMPLOYEE, values, props)
export const validationEventFn = (values: ICalendarEventForm, props: any) => zodErrorsToFormErrors(eventSchema, FORM.CALENDAR_EVENT_FORM, values, props)
export const validationIndustriesFn = (values: IIndustriesForm, props: any) => zodErrorsToFormErrors(industriesSchema, FORM.INDUSTRIES, values, props)
export const validationIndustryFn = (values: IIndustryForm, props: any) => zodErrorsToFormErrors(industrySchema, FORM.INDUSTRY, values, props)
export const validationLanguageFn = (values: ILanguageForm, props: any) => zodErrorsToFormErrors(languageSchema, FORM.LANGUAGES, values, props)
export const validationLoginFn = (values: ILoginForm, props: any) => zodErrorsToFormErrors(loginSchema, FORM.LOGIN, values, props)
export const validationLoginAsPartnerFn = (values: ILoginAsPartnerForm, props: any) => zodErrorsToFormErrors(loginAsPartnerSchema, FORM.LOGIN_AS_PARTNER, values, props)
export const validationNoteFn = (values: INoteForm, props: any) => zodErrorsToFormErrors(noteSchema, FORM.NOTE, values, props)
export const validationDynamicNotificationsSettingsFn = (values: IDynamicNotificationsSettingsForm, props: any) =>
	zodErrorsToFormErrors(dynamicNotificationsSettingSchema, FORM.DYNAMIC_NOTIFICATIONS_SETTINGS, values, props)
export const validationDynamicNotificationsFn = (values: IDynamicNotificationsForm, props: any) =>
	zodErrorsToFormErrors(dynamicNotificationsSchema, FORM.DYNAMIC_NOTIFICATIONS, values, props)
export const validationOpenHoursNoteFn = (values: IOpenHoursNoteForm, props: any) => zodErrorsToFormErrors(openHoursNoteSchema, FORM.OPEN_HOURS_NOTE, values, props)
export const validationCreatePasswordFn = (values: ICreatePasswordForm, props: any) => zodErrorsToFormErrors(createPasswordSchema, FORM.CREATE_PASSWORD, values, props)
export const validationForgotPasswordFn = (values: IForgotPasswordForm, props: any) => zodErrorsToFormErrors(forgotPasswordSchema, FORM.FORGOT_PASSWORD, values, props)
export const validationRechargeSmsCreditFn = (values: IRechargeSmsCreditForm, props: any) => zodErrorsToFormErrors(rechargeSmsCreditSchema, FORM.RECHARGE_SMS_CREDIT, values, props)
export const validationRechargeSmsCreditFilterFn = (values: IRechargeSmsCreditFilterForm, props: any) =>
	zodErrorsToFormErrors(rechargeSmsCreditFilterSchema, FORM.RECHARGE_SMS_CREDIT_FILTER, values, props)
export const validationRegistrationFn = (values: IRegistrationForm, props: any) => zodErrorsToFormErrors(registrationSchema, FORM.REGISTRATION, values, props)
export const validationReservationsFn = (values: ICalendarReservationForm, props: any) => zodErrorsToFormErrors(reservationsSchema, FORM.CALENDAR_RESERVATION_FORM, values, props)
export const validationReviewFilterFn = (values: IReviewFilterForm, props: any) => zodErrorsToFormErrors(reviewSchema, FORM.REVIEWS_FILTER, values, props)
export const validationEditEmployeeRoleFn = (values: IEditEmployeeRoleForm, props: any) => zodErrorsToFormErrors(employeeRoleSchema, FORM.EDIT_EMPLOYEE_ROLE, values, props)
export const validationEditUserRoleFn = (values: IEditUserRoleForm, props: any) => zodErrorsToFormErrors(userRoleSchema, FORM.EDIT_USER_ROLE, values, props)
export const validationSalonFn = (values: ISalonForm, props: any) => zodErrorsToFormErrors(salonSchema, FORM.SALON, values, props)
export const validationServiceFn = (values: IServiceForm, props: any) => zodErrorsToFormErrors(serviceSchema, FORM.SERVICE_FORM, values, props)
export const validationServiceCustomFn = (values: IServiceCustomForm, props: any) => zodErrorsToFormErrors(serviceCustomSchema, FORM.SERVICE_CUSTOM_FORM, values, props)
export const validationEmployeeServiceEditFn = (values: IEmployeeServiceEditForm, props: any) =>
	zodErrorsToFormErrors(employeeServiceSchema, FORM.EMPLOYEE_SERVICE_EDIT, values, props)
export const validationSmsUnitPricesFn = (values: ISmsUnitPricesForm, props: any) => zodErrorsToFormErrors(smsUnitPricesSchema, FORM.SMS_UNIT_PRICES_FORM, values, props)
export const validationSpecialistContactFn = (values: ISpecialistContactForm, props: any) => zodErrorsToFormErrors(specialistSchema, FORM.SPECIALIST_CONTACT, values, props)
export const validationSupportContactFn = (values: ISupportContactForm, props: any) => zodErrorsToFormErrors(supportContactSchema, FORM.SUPPORT_CONTACT, values, props)
export const validationEditUserFn = (values: IUserAccountForm, props: any) => zodErrorsToFormErrors(editUserSchema, FORM.USER_ACCOUNT, values, props)
export const validationCreateUserFn = (values: IUserAccountForm, props: any) => zodErrorsToFormErrors(createUserSchema, FORM.ADMIN_CREATE_USER, values, props)
export const validationUserLanguageSelectionFn = (values: IUserLanguageSelectionForm, props: any) =>
	zodErrorsToFormErrors(userLanguageSelectionSchema, FORM.USER_LANGUAGE_SELECTION, values, props)
export const validationAccountSettingsFn = (values: IAccountSettingsForm, props: any) => zodErrorsToFormErrors(accountSettingsSchema, FORM.ACCOUNT_SETTINGS, values, props)
export const validationAffiliateProgramSmsTransactionFn = (values: IAffiliateProgramSmsTransactionForm, props: any) =>
	zodErrorsToFormErrors(affiliateProgramSmsTransactionSchema, FORM.AFFILIATE_PROGRAM_SMS_TRANSACTION, values, props)
export const validationAffiliateProgramVoucherTransactionFn = (values: IAffiliateProgramVoucherTransactionForm, props: any) =>
	zodErrorsToFormErrors(affiliateProgramVoucherTransactionSchema, FORM.AFFILIATE_PROGRAM_VOUCHER_TRANSACTION, values, props)
export const validationIbanCalculatorFn = (values: IIbanCalculatorForm, props: any) => zodErrorsToFormErrors(ibanCalculatorSchema, FORM.IBAN_CALCULATOR, values, props)
export const validationChangelogsFn = (values: IChangelogForm, props: any) => zodErrorsToFormErrors(changelogSchema, FORM.CHANGELOG, values, props)
export const validationEmployeeShiftsFn = (values: IEmployeeShiftsForm, props: any) => zodErrorsToFormErrors(employeeShiftsSchema, FORM.EMPLOYEE_SHIFTS, values, props)
export const validationEmployeeShiftPlanningFn = (values: IEmployeeShiftPlanningForm, props: any) =>
	zodErrorsToFormErrors(employeeShiftPlanningSchema, FORM.EMPLOYEE_SHIFT_PLANNING, values, props)
export const validationPhotoalbumFn = (values: IPhotoalbumForm, props: any) => zodErrorsToFormErrors(photoalbumSchema, FORM.PHOTOALBUM, values, props)
export const validationPhotoDescriptionFn = (values: IPhotoDescriptionForm, props: any) => zodErrorsToFormErrors(photoDescriptionSchema, FORM.PHOTO_DESCRIPTION, values, props)
export const validationReservationSystemDisabledFeedbackFn = (values: IReservationSystemDisabledFeedbackForm, props: any) =>
	zodErrorsToFormErrors(reservationSystemDisabledFeedbackSchema, FORM.RESERVATION_SYSTEM_DISABLED_FEEDBACK_FORM, values, props)
export const validationImportCustomersFn = (values: IImportCustomersForm, props: any) => zodErrorsToFormErrors(importCustomers, FORM.IMPORT_CUSTOMERS, values, props)
export const validationImportReservationsFn = (values: IImportReservationsForm, props: any) => zodErrorsToFormErrors(importReservations, FORM.IMPORT_RESERVATIONS, values, props)
export const validationImportSalonsFn = (values: IImportSalonsForm, props: any) => zodErrorsToFormErrors(importSalons, FORM.IMPORT_SALONS, values, props)
export const validationCreateReservationFeedbackFn = (values: ICreateReservationFeedbackForm, props: any) =>
	zodErrorsToFormErrors(createReservationFeedbackSchema, FORM.CREATE_RESERVATION_FEEDBACK, values, props)
