import React from 'react'
import { Radio, Form } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { map } from 'lodash'
import cx from 'classnames'

// helpers
import { formFieldID } from '../utils/helper'

const { Item } = Form

type Props = WrappedFieldProps &
	InputProps &
	FormItemLabelProps & {
		options: any
		direction: 'vertical' | 'horizontal'
	}

/**
 * Use this component when you need to have a standard radio buttons. If you need to have a component with radio group behavior, but with hidden radio buttons, use "RadioGroupCustomField.tsx" instead
 */
const RadioGroupField = (props: Props) => {
	const {
		id,
		input,
		label,
		required,
		options,
		meta: { error, touched, form },
		className,
		style,
		direction = 'horizontal',
		size
	} = props

	const radioOptions = map(options, (option) => {
		if (typeof option === 'string') {
			return (
				<Radio key={option} value={option}>
					{option}
				</Radio>
			)
		}
		return (
			<Radio className={option.className} key={`${option.value}`} value={option.value}>
				{option.label}
				{option.customContent ? option.customContent : null}
			</Radio>
		)
	})

	return (
		<Item
			required={required}
			label={label}
			help={touched && error}
			validateStatus={error && touched ? 'error' : undefined}
			style={style}
			className={cx(className, 'noti-radio', {
				'noti-radio-has-error': error && touched,
				'noti-radio-horizontal': direction === 'horizontal',
				'noti-radio-vertical': direction === 'vertical'
			})}
		>
			<Radio.Group id={id || formFieldID(form, input.name)} size={size} value={input.value || []} onChange={input.onChange}>
				{radioOptions}
			</Radio.Group>
		</Item>
	)
}

export default RadioGroupField
