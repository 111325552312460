import i18next, { TFunction } from 'i18next'
import { Gutter } from 'antd/lib/grid/row'
import { AliasToken } from 'antd/es/theme/internal'
import { FormatterInput } from '@fullcalendar/core'
import { UseGoogleLoginOptionsAuthCodeFlow } from '@react-oauth/google'

// locales
import sk_SK from 'antd/locale/sk_SK'
import cs_CZ from 'antd/locale/cs_CZ'
import en_GB from 'antd/locale/en_GB'
import hu_HU from 'antd/locale/hu_HU'
import ro_RO from 'antd/locale/ro_RO'
import bg_BG from 'antd/locale/bg_BG'
/* import it_IT from 'antd/locale/it_IT' */

// env
import envConfig from '../config'

export enum CYPRESS_CLASS_NAMES {
	LOGOUT_BUTTON = 'noti-logout-button',
	MY_ACCOUNT = 'noti-my-account',
	MY_ACCOUNT_PERSONAL_DATA = 'noti-my-account-personal-data',
	MY_ACCOUNT_DOCUMENTS = 'noti-my-account-documents',
	FORBIDDEN_MODAL = 'noti-forbidden-modal',
	ASSIGN_SERVICES_BUTTON = 'noti-assign-services-button',
	VOUCHER_COUPON_CODE = 'noti-voucher-coupon-code',
	SERVICE_CUSTOM_INDUSTRIES_DROPDOWN = 'noti-service-custom-industries-dropdown',
	SERVICE_CUSTOM_CATEGORIES_DROPDOWN = 'noti-service-custom-categories-dropdown',
	SERVICE_EMPLOYEES_DROPDOWN = 'noti-service-employees-dropdown',
	SERVICE_CATEGORY_PARAMETER_DROPDOWN = 'noti-service-category-parameter-dropdown',
	SERVICE_CATEGORY_PARAMETERS_COLLAPSE = 'noti-service-category-parameter-collapse',
	SERVICE_CATEGORY_PARAMETER_PANEL_HEADER_TEXT = 'noti-service-category-parameter-panel-header-text',
	OPTION_RENDER_ICON_TEXT = 'noti-option-render-icon-text',
	MY_ACCOUNT_NEW_DOCUMENT_BADGE = 'noti-my-account-documents-badge',
	MY_DOCUMENTS_NEW_BADGE = 'noti-my-documents-new-badge',
	MY_DOCUMENTS_READ_BADGE = 'noti-my-documents-read-badge',
	MY_DOCUMENT_LINK = 'noti-my-document-link',
	SELECT_TEMPLATE_CUSTOMER_DROPDOWN = 'noti-select-template-customers-dropdown',
	SELECT_TEMPLATE_RESERVATIONS_DROPDOWN = 'noti-select-template-reservations-dropdown',
	ADDRESS_SEARCH_BOX = 'noti-address-search-box',
	SERVICE_EMPLOYEE_PANEL_HEADER = 'noti-service-employee-panel-header',
	SERVICE_EMPLOYEE_SERVICE_EDIT_BTN = 'noti-service-employee-service-edit-btn',
	SERVICE_EMPLOYEE_SERVICE_DELETE_BTN = 'noti-service-employee-service-delete-btn',
	EMPLOYEE_SERVICE_PANEL_HEADER = 'noti-employee-service-panel-header',
	SERVICE_EMPLOYEE_EDIT_MODAL = 'edit-employee-service-modal',
	NOTI_REPEAT_ON_CHECKBOX = 'noti-repeat-on-checkbox',
	SERVICE_CATEGORY_PARAMETER_DROPDOWN_PARAMETER_NAME = 'noti-service-category-parameter-dropdown-parameter-name'
}

export enum GRECAPTCHA_ACTIONS {
	CREATE_SALON_FORM = 'create_salon_form',
	REGISTER_FORM = 'register_form',
	FORGOTTEN_PASSWORD_FORM = 'forgotten_password_form'
}

export enum KEYBOARD_KEY {
	ENTER = 'Enter'
}

export enum NAMESPACE {
	PATHS = 'paths',
	LOC = 'loc'
}

export enum LANGUAGE {
	SK = 'sk',
	CZ = 'cs',
	EN = 'en',
	HU = 'hu',
	RO = 'ro',
	BG = 'bg'
	/* IT = 'it' */
}

export const LOCALES = {
	[LANGUAGE.SK]: {
		antD: sk_SK,
		countryCode: 'SK',
		ISO_Code: 'sk-SK'
	},
	[LANGUAGE.CZ]: {
		antD: cs_CZ,
		countryCode: 'CZ',
		ISO_Code: 'cs-CZ'
	},
	[LANGUAGE.EN]: {
		antD: en_GB,
		countryCode: 'EN',
		ISO_Code: 'en-GB'
	},
	[LANGUAGE.HU]: {
		antD: hu_HU,
		countryCode: 'HU',
		ISO_Code: 'hu-HU'
	},
	[LANGUAGE.RO]: {
		antD: ro_RO,
		countryCode: 'RO',
		ISO_Code: 'ro-RO'
	},
	[LANGUAGE.BG]: {
		antD: bg_BG,
		countryCode: 'BG',
		ISO_Code: 'bg-BG'
	} /* ,
	[LANGUAGE.IT]: {
		antD: it_IT,
		countryCode: 'IT',
		ISO_Code: 'it-IT'
	} */
}

export enum BROWSERS {
	CHROME = 'chrome',
	SAFARI = 'safari',
	FIREFOX = 'firefox',
	EDGE = 'edge',
	OPERA = 'opera'
}

export enum BROWSER_TYPE {
	UNKNOWN = 'unknown',
	SUPPORTED = 'supported',
	UNSUPPORTED = 'unsupported'
}

export enum IMPORT_TYPE {
	IMPORT = 'IMPORT',
	UPLOAD = 'UPLOAD'
}

export enum ASSET_TYPE {
	B2C_PRIVACY_POLICY = 'B2C_PRIVACY_POLICY',
	B2C_RESERVATIONS_TERMS = 'B2C_RESERVATIONS_TERMS',
	B2B_PRIVACY_POLICY = 'B2B_PRIVACY_POLICY',
	B2B_APP_TERMS_CONDITIONS = 'B2B_APP_TERMS_CONDITIONS',
	B2C_PLACEHOLDER_HOMESCREEN = 'B2C_PLACEHOLDER_HOMESCREEN'
}

export const MIN_SUPPORTED_BROWSER_VERSION = (browserName?: string) => {
	switch (browserName) {
		case BROWSERS.CHROME:
			// Released: 2020-02-04
			return 80

		case BROWSERS.SAFARI:
			// Released: 2020-09-16
			return 14

		case BROWSERS.FIREFOX:
			// Released: 2020-01-07
			return 72

		case BROWSERS.EDGE:
			// Released: 2020-01-15
			return 79

		case BROWSERS.OPERA:
			// Released: 2020-01-28
			return 67
		default:
			return -1
	}
}

export const CHANGE_DEBOUNCE_TIME = 300 // 300ms change debounce time for forms that have onChange submit

export const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 13 // 13 minutes

export const REFRESH_PAGE_INTERVAL = 1000 * 60 * 60 * 4 // 4 hours

export const REFRESH_CALENDAR_INTERVAL = 1000 * 60 * 2 // 2 minutes

export const ASYNC_JOB_PROGRESS_INTERVAL = 3000 // in ms

export const DEFAULT_LANGUAGE = LANGUAGE.EN

export const DEFAULT_PHONE_PREFIX = 'CZ'

export const DEFAULT_CURRENCY = {
	code: '',
	symbol: ''
}

export enum MSG_TYPE {
	INFO = 'INFO',
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	SUCCESS = 'SUCCESS'
}

export enum ERROR_MSG_CODE {
	MISSING_COUNTRY_CODE = 'MISSING_COUNTRY_CODE',
	RECAPTCHA_TOKEN_NOT_PROVIDED = 'RECAPTCHA_TOKEN_NOT_PROVIDED',
	RECAPTCHA_VERIFICATION_FAILED = 'RECAPTCHA_VERIFICATION_FAILED'
}

export enum FIELD_MODE {
	INPUT = 'INPUT',
	FILTER = 'FILTER'
}

export enum REQUEST_TYPE {
	DELETE = 'DELETE',
	PATCH = 'PATCH',
	PUT = 'PUT',
	GET = 'GET',
	POST = 'POST'
}

export enum FILTER_ENTITY {
	EMPLOYEE = 'EMPLOYEE',
	SALON = 'SALON',
	SERVICE = 'SERVICE',
	USER = 'USER',
	BASIC_SALON = 'BASIC_SALON',
	NOTINO_USER = 'NOTINO_USER',
	COSMETICS = 'COSMETICS',
	CUSTOMER = 'CUSTOMER'
}

export const ROW_GUTTER_X_DEFAULT = [4, 0] as Gutter
export const ROW_GUTTER_X_M = [16, 0] as Gutter

export const DROPDOWN_POSITION = {
	BOTTOM_LEFT: {
		points: ['tl', 'bl'],
		offset: [0, 4],
		overflow: {
			adjustX: false,
			adjustY: false
		}
	},
	BOTTOM_RIGHT: {
		points: ['tr', 'br'],
		offset: [0, 4],
		overflow: {
			adjustX: false,
			adjustY: false
		}
	}
}

export enum FORM {
	CUSTOMER = 'CUSTOMER',
	USER_ACCOUNT = 'USER_ACCOUNT',
	SALON = 'SALON',
	LOGIN = 'LOGIN',
	LOGIN_AS_PARTNER = 'LOGIN_AS_PARTNER',
	CATEGORY = 'CATEGORY',
	CATEGORY_PARAMS = 'CATEGORY_PARAMS',
	CATEGORY_PARAMS_FILTER = 'CATEGORY_PARAMS_FILTER',
	COSMETIC = 'COSMETIC',
	COSMETICS_FILTER = 'COSMETICS_FILTER',
	SALONS_FILTER_ACTIVE = 'SALONS_FILTER_ACTIVE',
	SALONS_FILTER_DELETED = 'SALONS_FILTER_DELETED',
	ACTIVATION = 'ACTIVATION',
	FORGOT_PASSWORD = 'FORGOT_PASSWORD',
	CREATE_PASSWORD = 'CREATE_PASSWORD',
	REGISTRATION = 'REGISTRATION',
	USER_PROFILE = 'USER_PROFILE',
	ADMIN_USERS_FILTER = 'ADMIN_USERS_FILTER',
	DOCUMENTS_FILTER = 'DOCUMENTS_FILTER',
	EMPLOYEES_FILTER = 'EMPLOYEES_FILTER',
	RESERVATIONS_FILTER = 'RESERVATIONS_FILTER',
	NOTINO_RESERVATIONS_FILTER = 'NOTINO_RESERVATIONS_FILTER',
	CREATE_SALON_FROM = 'CREATE_SALON_FROM',
	ROLE_FORM = 'ROLE_FORM',
	ADMIN_CREATE_USER = 'ADMIN_CREATE_USER',
	ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER',
	EDIT_USER_ROLE = 'EDIT_USER_ROLE',
	SERVICE_FORM = 'SERVICE_FORM',
	SERVICE_CUSTOM_FORM = 'SERVICE_CUSTOM_FORM',
	CUSTOMERS_FILTER = 'CUSTOMERS_FILTER',
	SERVICES_FILTER = 'SERVICES_FILTER',
	OPEN_HOURS_NOTE = 'OPEN_HOURS_NOTE',
	EMPLOYEE = 'EMPLOYEE',
	EMPLOYEE_SERVICES = 'EMPLOYEE_SERVICES',
	INVITE_EMPLOYEE = 'INVITE_EMPLOYEE',
	SUPPORT_CONTACTS_FILTER = 'SUPPORT_CONTACTS_FILTER',
	SUPPORT_CONTACT = 'SUPPORT_CONTACT',
	NOTE = 'NOTE',
	NOTINO_USER = 'NOTINO_USER',
	EDIT_EMPLOYEE_ROLE = 'EDIT_EMPLOYEE_ROLE',
	IMPORT_SALONS = 'IMPORT_SALONS',
	IMPORT_CUSTOMERS = 'IMPORT_CUSTOMERS',
	IMPORT_RESERVATIONS = 'IMPORT_RESERVATIONS',
	SALON_HISTORY_FILTER = 'SALON_HISTORY_FILTER',
	INDUSTRIES = 'INDUSTRIES',
	INDUSTRY = 'INDUSTRY',
	LANGUAGES = 'LANGUAGES',
	LANGUAGES_FILTER = 'LANGUAGES_FILTER',
	SPECIALIST_CONTACT = 'SPECIALIST_CONTACT',
	SPECIALIST_CONTACT_FILTER = 'SPECIALIST_CONTACT_FILTER',
	SALON_BILLING_INFO = 'SALON_BILLING_INFO',
	FILTER_REJECTED_SUGGESTIONS = 'FILTER_REJECTED_SUGGESTIONS',
	CALENDAR_FILTER = 'CALENDAR_FILTER',
	CALENDAR_RESERVATION_FORM = 'CALENDAR_RESERVATION_FORM',
	CONFIRM_BULK_FORM = 'CONFIRM_BULK_FORM',
	RESERVATION_SYSTEM_SETTINGS = 'RESERVATION_SYSTEM_SETTINGS',
	HEADER_COUNTRY_FORM = 'HEADER_COUNTRY_FORM',
	EMPLOYEE_SERVICE_EDIT = 'EMPLOYEE_SERVICE_EDIT',
	CALENDAR_EVENT_FORM = 'CALENDAR_EVENT_FORM',
	REVIEWS_FILTER = 'REVIEWS_FILTER',
	SMS_UNIT_PRICES_FORM = 'SMS_UNIT_PRICES_FORM',
	SMS_UNIT_PRICES_FILTER = 'SMS_UNIT_PRICES_FILTER',
	SMS_HISTORY_FILTER = 'SMS_HISTORY_FILTER',
	RECHARGE_SMS_CREDIT = 'RECHARGE_SMS_CREDIT',
	RECHARGE_SMS_CREDIT_FILTER = 'RECHARGE_SMS_CREDIT_FILTER',
	INDUSTRY_FILTER = 'INDUSTRY_FILTER',
	SALON_IDS_FORM = 'SALON_IDS_FORM',
	DOCUMENTS_FORM = 'DOCUMENTS_FORM',
	SALONS_TO_CHECK_FILTER = 'SALONS_TO_CHECK_FILTER',
	USER_LANGUAGE_SELECTION = 'USER_LANGUAGE_SELECTION',
	DYNAMIC_NOTIFICATIONS = 'DYNAMIC_NOTIFICATIONS',
	DYNAMIC_NOTIFICATIONS_SETTINGS = 'DYNAMIC_NOTIFICATIONS_SETTINGS',
	DYNAMIC_NOTIFICATIONS_FILTER = 'DYNAMIC_NOTIFICATIONS_FILTER',
	ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS ',
	AFFILIATE_PROGRAM_SMS_TRANSACTION = 'AFFILIATE_PROGRAM_SMS_TRANSACTION',
	AFFILIATE_PROGRAM_VOUCHER_TRANSACTION = 'AFFILIATE_PROGRAM_VOUCHER_TRANSACTION',
	IBAN_CALCULATOR = 'IBAN_CALCULATOR',
	CHANGELOGS_FILTER = 'CHANGELOGS_FILTER',
	CHANGELOG = 'CHANGELOG',
	VOUCHER_FILTER = 'VOUCHER_FILTER',
	EMPLOYEE_SHIFTS = 'EMPLOYEE_SHIFTS',
	EMPLOYEE_SHIFT_PLANNING = 'EMPLOYEE_SHIFT_PLANNING',
	PHOTOALBUM = 'PHOTOALBUM',
	PHOTO_DESCRIPTION = 'PHOTO_DESCRIPTION',
	RESERVATION_SYSTEM_DISABLED_FEEDBACK_FORM = 'RESERVATION_SYSTEM_DISABLED_FEEDBACK_FORM',
	CUSTOM_SERVICES_FILTER = 'CUSTOM_SERVICES_FILTER',
	CREATE_RESERVATION_FEEDBACK = 'CREATE_RESERVATION_FEEDBACK'
}

export const FORMS_WITH_COUNTRY_SELECT = [
	FORM.SALONS_FILTER_ACTIVE,
	FORM.SALONS_FILTER_DELETED,
	FORM.SALONS_TO_CHECK_FILTER,
	FORM.REVIEWS_FILTER,
	FORM.NOTINO_RESERVATIONS_FILTER,
	FORM.CUSTOM_SERVICES_FILTER
]

export enum PERMISSION {
	NOTINO_SUPER_ADMIN = 'NOTINO_SUPER_ADMIN',
	NOTINO_ADMIN = 'NOTINO_ADMIN',
	NOTINO = 'NOTINO',
	PARTNER = 'PARTNER',
	USER_CREATE = 'USER_CREATE',
	USER_BROWSING = 'USER_BROWSING',
	USER_EDIT = 'USER_EDIT',
	USER_DELETE = 'USER_DELETE',
	ENUM_EDIT = 'ENUM_EDIT',
	SALON_PUBLICATION_RESOLVE = 'SALON_PUBLICATION_RESOLVE',
	IMPORT_SALON = 'IMPORT_SALON',
	REVIEW_READ = 'REVIEW_READ',
	REVIEW_VERIFY = 'REVIEW_VERIFY',
	REVIEW_DELETE = 'REVIEW_DELETE',
	PARTNER_ADMIN = 'PARTNER_ADMIN',
	SALON_UPDATE = 'SALON_UPDATE',
	SALON_DELETE = 'SALON_DELETE',
	SALON_BILLING_UPDATE = 'SALON_BILLING_UPDATE',
	SERVICE_CREATE = 'SERVICE_CREATE',
	SERVICE_UPDATE = 'SERVICE_UPDATE',
	SERVICE_DELETE = 'SERVICE_DELETE',
	CUSTOMER_CREATE = 'CUSTOMER_CREATE',
	CUSTOMER_UPDATE = 'CUSTOMER_UPDATE',
	CUSTOMER_DELETE = 'CUSTOMER_DELETE',
	EMPLOYEE_CREATE = 'EMPLOYEE_CREATE',
	EMPLOYEE_UPDATE = 'EMPLOYEE_UPDATE',
	EMPLOYEE_DELETE = 'EMPLOYEE_DELETE',
	EMPLOYEE_ROLE_UPDATE = 'EMPLOYEE_ROLE_UPDATE',
	CALENDAR_EVENT_CREATE = 'CALENDAR_EVENT_CREATE',
	CALENDAR_EVENT_UPDATE = 'CALENDAR_EVENT_UPDATE',
	CALENDAR_EVENT_DELETE = 'CALENDAR_EVENT_DELETE',
	READ_WALLET = 'READ_WALLET',
	SMS_UNIT_PRICE_EDIT = 'SMS_UNIT_PRICE_EDIT',
	CATEGORY_EDIT = 'CATEGORY_EDIT',
	CATEGORY_PARAMETER_EDIT = 'CATEGORY_PARAMETER_EDIT',
	COSMETIC_EDIT = 'COSMETIC_EDIT',
	LANGUAGE_EDIT = 'LANGUAGE_EDIT',
	WALLET_TRANSACTION_CREATE = 'WALLET_TRANSACTION_CREATE',
	DYNAMIC_NOTIFICATION_CREATE = 'DYNAMIC_NOTIFICATION_CREATE',
	DYNAMIC_NOTIFICATION_BROWSING = 'DYNAMIC_NOTIFICATION_BROWSING',
	CREATE_TRANSACTION_AFFILIATE_WALLET_TO_SMS_WALLET = 'CREATE_TRANSACTION_AFFILIATE_WALLET_TO_SMS_WALLET'
}

export const ADMIN_PERMISSIONS: PERMISSION[] = [PERMISSION.NOTINO_SUPER_ADMIN, PERMISSION.NOTINO_ADMIN]

export enum RESOLUTIONS {
	SM = 'SM',
	MD = 'MD',
	L = 'L',
	XL = 'XL',
	XXL = 'XXL',
	XXXL = 'XXXL'
}
export const NOT_ALLOWED_REDIRECT_PATHS = ['/404', '/403']

export enum TOKEN_AUDIENCE {
	API = 'jwt-api',
	FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD',
	INVITATION = 'INVITATION',
	CANCEL_RESERVATION = 'CANCEL_RESERVATION'
}

export enum SALON_TABS_KEYS {
	SALON_DETAIL = 'SALON_DETAIL',
	SALON_HISTORY = 'SALON_HISTORY'
}

export enum SALONS_TAB_KEYS {
	ACTIVE = 'ACTIVE',
	DELETED = 'DELETED',
	MISTAKES = 'MISTAKES',
	TO_CHECK = 'TO_CHECK'
}

export enum EMPLOYEES_TAB_KEYS {
	ACTIVE = 'active',
	DELETED = 'deleted'
}

export enum DASHBOARD_TAB_KEYS {
	SALONS_DASHBOARD = 'SALONS_DASHBOARD',
	RESERVATIONS_DASHBOARD = 'RESERVATIONS_DASHBOARD'
}

export enum RESERVATIONS_STATE {
	PENDING = 'PENDING',
	ALL = 'ALL'
}

export enum REVIEWS_TAB_KEYS {
	PUBLISHED = 'published',
	DELETED = 'deleted'
}

export enum NOTIFICATIONS_TAB_KEYS {
	DYNAMIC_NOTIFICATIONS = 'DYNAMIC_NOTIFICATIONS',
	CHANGELOGS = 'CHANGELOGS'
}

export enum EMPLOYEE_TAB_KEYS {
	PERSONAL_DATA = 'PERSONAL_DATA',
	SERVICES = 'SERVICES',
	RESERVATIONS = 'RESERVATIONS',
	SHIFTS = 'SHIFTS'
}

export enum CATEGORIES_TAB_KEYS {
	CATEGORIES = 'CATEGORIES',
	CUSTOM_SERVICES = 'CUSTOM_SERVICES'
}

export enum PAGE {
	SALONS = 'SALONS',
	SALON_DETAIL = 'SALON_DETAIL',
	CATEGORIES = 'CATEGORIES',
	CATEGORY_PARAMETERS = 'CATEGORY_PARAMETERS',
	USER_PROFILE = 'USER_PROFILE',
	USERS = 'USERS',
	ROLES = 'ROLES',
	CUSTOMERS = 'CUSTOMERS',
	PERMISSIONS = 'PERMISSIONS',
	HOME = 'HOME',
	HOME_PARTNER = 'HOME_PARTNER',
	MY_ACCOUNT = 'MY_ACCOUNT',
	ACTIVATION = 'ACTIVATION',
	EMPLOYEES = 'EMPLOYEES',
	SUPPORT_CONTACTS = 'SUPPORT_CONTACTS',
	COSMETICS = 'COSMETICS',
	LANGUAGES = 'LANGUAGES',
	INDUSTRIES_AND_SERVICES = 'INDUSTRIES_AND_SERVICES',
	SERVICES_SETTINGS = 'SERVICES_SETTINGS',
	PENDING_INVITES = 'PENDING_INVITES',
	SPECIALIST_CONTACTS = 'SPECIALIST_CONTACTS',
	BILLING_INFO = 'BILLING_INFO',
	CALENDAR = 'CALENDAR',
	SALON_SETTINGS = 'SALON_SETTINGS',
	RESERVATIONS = 'RESERVATIONS',
	NOTINO_RESERVATIONS = 'NOTINO_RESERVATIONS',
	REVIEWS = 'REVIEWS',
	SMS_CREDIT = 'SMS_CREDIT',
	SMS_CREDITS = 'SMS_CREDITS',
	AFFILIATE_PROGRAM = 'AFFILIATE_PROGRAM',
	DOCUMENTS = 'DOCUMENTS',
	MY_DOCUMENTS = 'MY_DOCUMENTS',
	DYNAMIC_NOTIFICATIONS = 'DYNAMIC_NOTIFICATIONS',
	ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
	PHOTOALBUMS = 'PHOTOALBUMS'
}

export enum PARAMETER_TYPE {
	ENUM = 'ENUM',
	TIME = 'TIME'
}

export const NEW_ID_PREFIX = 'NEW'

export const DEFAULT_DATE_INPUT_FORMAT = 'DD.MM.YYYY'

export const DEFAULT_DATE_INIT_FORMAT = 'YYYY-MM-DD'

export const DEFAULT_TIME_FORMAT = 'HH:mm'

export const TIME_FORMAT_12_HOUR = 'hh:mm A'

export const DEFAULT_DATE_WITH_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

export const MONTH_NAME_YEAR_FORMAT = 'MMM YYYY'

export const DATE_TIME_PARSER_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_PARSER_FORMAT = `${DATE_TIME_PARSER_DATE_FORMAT}:HH:mm`

export const INVALID_DATE_FORMAT = 'INVALID_DATE_FORMAT'

export const BACK_DATA_QUERY = 'backData'

export const PIN_LENGTH = 6

export const PAGINATION = {
	defaultPageSize: 25,
	pageSizeOptions: [25, 50, 100],
	limit: 25 // 25 | 50 | 100
}

export const QUERY_LIMIT = {
	MAX_255: 255
}

export const UPLOAD = {
	MAX_FILE_SIZE: 10 * 10 ** 6, // 10 MB,
	MAX_COUNT: 100,
	USER_ACCOUNT_AVATAR: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png',
		MAX_COUNT: 1
	},
	CATEGORY_IMAGE: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png',
		MAX_COUNT: 1
	},
	CATEGORY_ICON: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png',
		MAX_COUNT: 1
	},
	COSMETIC_LOGO: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png',
		MAX_COUNT: 1
	},
	LANGUAGE_FLAG: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png',
		MAX_COUNT: 1
	},
	SALON_LOGO: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png',
		MAX_COUNT: 1
	},
	SALON_GALLERY: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png',
		MAX_COUNT: 999
	},
	SALON_PRICELISTS: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png,application/pdf',
		MAX_COUNT: 10
	},
	PHOTOALBUMS: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png',
		MAX_COUNT: 50
	},
	EMPLOYEE_LOGO: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png',
		MAX_COUNT: 1
	},
	CUSTOMER_LOGO: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png',
		MAX_COUNT: 1
	},
	CUSTOMER_GALLERY: {
		MAX_FILE_SIZE: 10 * 1048576, // 10 MB,
		ACCEPT: 'image/jpeg,image/png',
		MAX_COUNT: 25
	}
}

export enum FILE_FILTER_DATA_TYPE {
	PDF = 'PDF',
	IMAGE = 'IMAGE',
	DOC = 'DOC',
	EXCEL = 'EXCEL',
	OTHER = 'OTHER'
}

const PRAGUE_LOCATION = {
	lat: 50.0755381,
	lng: 14.4378005
}

export const MAP_CONFIG = {
	// eslint-disable-next-line no-underscore-dangle
	googleMapsApiKey: envConfig?.VITE_GOOGLE_MAPS_API_KEY,
	// https://developers.google.com/maps/documentation/get-map-id
	// 'DEMO_MAP_ID' explanation https://developers.google.com/maps/documentation/javascript/advanced-markers/migration
	mapID: 'DEMO_MAP_ID',
	defaultZoom: 15,
	minLatitude: -90,
	maxLatitude: 90,
	minLongitude: -180,
	maxLongitude: 180,
	minZoom: 8,
	maxZoom: 20,
	placeZoom: 16,
	defaultLocation: PRAGUE_LOCATION,
	locations: {
		[LANGUAGE.CZ]: PRAGUE_LOCATION,
		[LANGUAGE.EN]: PRAGUE_LOCATION,
		// Budapest
		[LANGUAGE.HU]: {
			lat: 47.497913,
			lng: 19.040236
		},
		// Bucharest
		[LANGUAGE.RO]: {
			lat: 44.439663,
			lng: 26.096306
		},
		// Sofia
		[LANGUAGE.BG]: {
			lat: 42.698334,
			lng: 23.319941
		},
		// Bratislava
		[LANGUAGE.SK]: {
			lat: 48.1485965,
			lng: 17.1077477
		}
	}
}

export enum MAP_LOADING_STATUS {
	NOT_LOADED = 'NOT_LOADED',
	LOADING = 'LOADING',
	LOADED = 'LOADED',
	FAILED = 'FAILED',
	AUTH_FAILURE = 'AUTH_FAILURE'
}

export enum SALON_FILTER_STATES {
	PUBLISHED = 'PUBLISHED',
	NOT_PUBLISHED = 'NOT_PUBLISHED',
	DELETED = 'DELETED',
	NOT_DELETED = 'NOT_DELETED',
	PENDING_PUBLICATION = 'PENDING_PUBLICATION',
	DECLINED = 'DECLINED',
	ALL = 'ALL'
}

export enum SALON_STATES {
	NOT_PUBLISHED = 'NOT_PUBLISHED',
	PUBLISHED = 'PUBLISHED',
	NOT_PUBLISHED_PENDING = 'NOT_PUBLISHED_PENDING',
	PUBLISHED_PENDING = 'PUBLISHED_PENDING',
	NOT_PUBLISHED_DECLINED = 'NOT_PUBLISHED_DECLINED',
	PUBLISHED_DECLINED = 'PUBLISHED_DECLINED'
}

export enum SALON_CREATE_TYPE {
	NON_BASIC = 'NON_BASIC',
	BASIC = 'BASIC'
}

export enum SALON_SOURCE_TYPE {
	NOTINO = 'NOTINO',
	PARTNER = 'PARTNER',
	IMPORT = 'IMPORT'
}

export enum SALON_PREMIUM_SOURCE_TYPE {
	NOTINO = 'NOTINO',
	PARTNER = 'PARTNER'
}

export enum SALON_FILTER_OPENING_HOURS {
	SET = 'SET',
	NOT_SET = 'NOT_SET'
}

export enum UPCOMING_RESERVATIONS {
	HAS_UPCOMING_RESERVATIONS = 'HAS_UPCOMING_RESERVATIONS',
	DOESNT_HAVE_UPCOMING_RESERVATIONS = 'DOESNT_HAVE_UPCOMING_RESERVATIONS'
}

export enum SALON_FILTER_RS {
	ENABLED = 'ENABLED',
	NOT_ENABLED = 'NOT_ENABLED'
}

export enum SALON_FILTER_RS_AVAILABLE_ONLINE {
	AVAILABLE = 'AVAILABLE',
	NOT_AVAILABLE = 'NOT_AVAILABLE',
	HAS_DAYS_WITH_AVAILABLE_SLOT = 'HAS_DAYS_WITH_AVAILABLE_SLOT'
}

export enum CUSTOM_SERVICE_CHECKED_STATE {
	CHECKED = 'CHECKED',
	NOT_CHECKED = 'NOT_CHECKED'
}

export const STRINGS = (t: TFunction) => ({
	accessEditTab: t('loc:Pre prístup potvrďte základné údaje'),
	areYouSureDelete: (entity: string) => t('loc:Naozaj chcete odstrániť {{entity}} ?', { entity }),
	addRecord: (entity: string) => t('loc:Pridať {{entity}}', { entity }),
	assign: (entity: string) => t('loc:Priradiť {{entity}}', { entity }),
	loading: t('loc:Načítavam...'),
	delete: (entity: string) => t('loc:Odstrániť {{entity}}', { entity }),
	save: (entity: string) => t('loc:Uložiť {{entity}}', { entity }),
	edit: (entity: string) => t('loc:Upraviť {{entity}}', { entity }),
	createRecord: (entity: string) => t('loc:Vytvoriť {{entity}}', { entity }),
	cancel: (entity: string) => t('loc:Zrušiť {{entity}}', { entity }),
	decline: (entity: string) => t('loc:Zamietnuť {{entity}}', { entity }),
	select: (entity: string) => t('loc:Vyberte {{entity}}', { entity }), // non searchable select field
	search: (entity: string) => t('loc:Vyhľadajte {{entity}}', { entity }), // searchable select field
	searchBy: (entity: string) => t('loc:Vyhľadajte podľa {{entity}}', { entity }), // input field vyhladavaci
	enter: (entity: string) => t('loc:Zadajte {{entity}}', { entity }), // all input fields
	MISSING_PERMISSIONS_TEXT: t('loc:Používateľovi chýbajú oprávnenia na akciu'),
	EMPTY_TABLE_COLUMN_PLACEHOLDER: '---'
})
export const TABLE_DRAG_AND_DROP_KEY = 'sort'

// NOTE: do not change days order!
export enum DAY {
	MONDAY = 'MONDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
	THURSDAY = 'THURSDAY',
	FRIDAY = 'FRIDAY',
	SATURDAY = 'SATURDAY',
	SUNDAY = 'SUNDAY'
}

export const MONDAY_TO_FRIDAY = 'MONDAY_TO_FRIDAY'

export enum GENDER {
	MALE = 'MALE',
	FEMALE = 'FEMALE'
}

export enum VALIDATION_MAX_LENGTH {
	LENGTH_3000 = 3000,
	LENGTH_1500 = 1500,
	LENGTH_1000 = 1000,
	LENGTH_500 = 500,
	LENGTH_255 = 255,
	LENGTH_200 = 200,
	LENGTH_100 = 100,
	LENGTH_75 = 75,
	LENGTH_60 = 60,
	LENGTH_50 = 50,
	LENGTH_30 = 30,
	LENGTH_20 = 20,
	LENGTH_10 = 10,
	LENGTH_5 = 5,
	LENGTH_2 = 2
}

export const LOCALIZATIONS = 'LOCALIZATIONS'

export enum UPLOAD_IMG_CATEGORIES {
	SALON_LOGO = 'SALON_LOGO',
	SALON_IMAGE = 'SALON_IMAGE',
	SALON_PRICELIST = 'SALON_PRICELIST',
	EMPLOYEE = 'EMPLOYEE_IMAGE',
	USER = 'USER_IMAGE',
	CATEGORY_IMAGE = 'CATEGORY_IMAGE',
	CATEGORY_ICON = 'CATEGORY_ICON',
	COSMETIC = 'COSMETIC_IMAGE',
	CUSTOMER = 'CUSTOMER_IMAGE',
	LANGUAGE_IMAGE = 'LANGUAGE_IMAGE',
	ASSET_DOC_TYPE = 'ASSET_DOC_TYPE',
	ASSET_IMAGE_TYPE = 'ASSET_IMAGE_TYPE',
	SALON_PHOTO_ALBUM = 'SALON_PHOTO_ALBUM'
}

export const ANTD_THEME_VARIABLES_OVERRIDE: Partial<AliasToken> = {
	// Override AntD colors
	colorPrimary: '#000000', // black
	colorLink: '#DC0069', // notino-pink
	colorText: '#404040', // true-gray-700,
	colorTextHeading: '#3F3F46', // cool-gray-900
	colorTextSecondary: '#BFBFBF', // notino-gray
	colorTextDisabled: '#9CA3AF', // cool-gray-100,
	colorSuccess: '#008700', // notino-success
	colorWarning: '#D97706', // amber-600
	colorError: '#D21414', // notino-red
	colorTextPlaceholder: '#BFBFBF', // notino-gray
	borderRadius: 2
}

export const URL_UPLOAD_FILE = '/api/b2b/admin/files/sign-urls'
export const DAYS = Object.keys(DAY) as DAY[]

export enum ACCOUNT_STATE {
	UNPAIRED = 'UNPAIRED',
	PENDING = 'PENDING',
	PAIRED = 'PAIRED'
}

export const UPLOAD_IN_PROGRESS_PROP = 'uploadInProgress'

export const DELETE_BUTTON_ID = 'delete-btn'

export const CREATE_BUTTON_ID = 'create-btn'

export const SUBMIT_BUTTON_ID = 'submit-btn'

export const SAVE_CONCEPT_BUTTON_ID = 'save-concept-btn'

export const SHOW_BUTTON_ID = 'show-btn'

export const CONFIRM_SUBMIT_BUTTON_ID = 'confirm-submit-btn'

export const SKIP_BUTTON_ID = 'skip-btn'

export const RESET_BUTTON_ID = 'reset-btn'

export const CANCEL_BUTTON_ID = 'cancel-btn'

export const CONFIRM_DELETE_BUTTON_ID = 'confirm-delete-btn'

export const CANCEL_DELETE_BUTTON_ID = 'cancel-delete-btn'

export const ADD_BUTTON_ID = 'add-btn'

export const ADD_PHONE_TO_ARRAY_BUTTON_ID = (inputName: string) => `${inputName}-add-btn`

export const ADD_MAIN_FEATURE_BUTTON_ID = 'add-btn-main-feature-btn'

export const ADD_SERVICE_BUTTON_ID = 'add-service-btn'

export const FILTER_BUTTON_ID = 'filter-btn'

export const FORGOT_PASSWORD_BUTTON_ID = 'forgot-password-btn'

export const SIGNUP_BUTTON_ID = 'signup-btn'

export const HELP_BUTTON_ID = 'help-password-btn'

export const CHANGE_PASSWORD_NEW_LINK_BUTTON_ID = 'change-password-new-link-btn'

export const CREATE_EMPLOYEE_BUTTON_ID = 'create-employee-btn'

export const CREATE_CUSTOMER_BUTTON_ID = 'create-customer-btn'

export const CREATE_DYNAMIC_NOTIFICATION_BUTTON_ID = 'create-dynamic-notification-btn'

export const CREATE_CHANGELOG_BUTTON_ID = 'create-changelog-btn'

export const CREATE_PHOTOALBUM_BUTTON_ID = 'create-photoalbum-btn'

export const OPEN_ASYNC_JOB_MODAL_BUTTON_ID = (jobType: ASYNC_JOB_TYPE) => `open-async-job-modal-btn-${jobType}`

export const ASYNC_JOB_MODAL_GENERATE_MORE_BTN_ID = (jobType: ASYNC_JOB_TYPE) => `async-job-modal-generate-more-btn-${jobType}`

export const SUBMIT_ASYNC_JOB_BUTTON_ID = (jobType: ASYNC_JOB_TYPE) => `submit-async-job-btn-${jobType}`

export const ROW_BUTTON_WITH_ID = (id: string) => `row-btn-with-id_${id}`

export const EMPLOYEE_ASSIGNED_SERVICE_ID = (id: string) => `employee-service-${id}`

export const EMPLOYEE_DELETE_SERVICE_BTN_ID = (id: string) => `employee-delete-service-btn-${id}`

export const INVITE_EMPLOYEE_BTN_ID = 'invite-employee-btn'

export const SMS_UNIT_PRICES_TABLE_ID = 'sms-unit-prices-table'

export const SMS_TIME_STATS_COUNTRY_PICKER_ID = 'sms-time-stats-country-picker'

export const SMS_TIME_STATS_DATE_PICKER_ID = 'sms-time-stats-date-picker'

export const RECHARGE_SMS_CREDIT_CONTINUE_BUTTON_ID = 'recharge-sms-credit-continue-button'

export const RECHARGE_SMS_CREDIT_BUTTON_ID = 'recharge-sms-credit-button'

export const DOWNLOAD_BUTTON_ID = 'download-button'

export const PUBLISHED_PREMIUM_SALONS_BAR_ID = 'published-premium-salons-bar'

export const SALON_STATS_MONTHLY_ID = 'salon-stats-monthly'

export const SALON_STATS_ANNUAL_ID = 'salon-stats-annual'

export const ENABLE_RS_BUTTON_ID = 'enable-rs-button'

export const ENABLE_RS_BUTTON_FAKE_BUTTON_ID = 'enable-rs-fake-button'

export const CHANGE_SERVICES_ORDER_BUTTON_ID = 'change-services-order-button'

export const CHANGE_SERVICES_ORDER_SAVE_BUTTON_ID = 'change-services-order-save-button'

export const CATEGORY_PARAMS_SWITCH_TYPE_ID = 'category-params-switch-type-id'

export const OPEN_RESERVATION_SETTINGS_BUTTON_ID = 'open-reservation-settings-btn'

export const EDIT_EMPLOYEE_SHIFTS_BUTTON_ID = 'edit-employee-shifts-btn'

export const ADD_EMPLOYEE_SHIFT_BUTTON_ID = 'add-employee-shift-btn'

export const ADD_TIME_RANGES_BUTTON_ID = (index: number) => `add-time-ranges-btn-${index}`

export const MAX_VALUES_PER_PARAMETER = 30

export const EDIT_PHOTO_DESCRIPTION_BTN_ID = (photoID: string) => `edit-photo-btn-${photoID}`

export const ADD_PHOTO_DESCRIPTION_BTN_ID = (photoID: string) => `add-photo-btn-${photoID}`

export const EDIT_EMPLOYEE_SHIFT_BTN_ID = (shiftID: string) => `edit-employee-shift-btn-${shiftID}`

export const SELECT_CATEGORY_BTN_ID = (categoryID: string) => `select-category-btn-${categoryID}`

export const NOTI_SPECIALIST_BUTTON_ID = 'noti-specialist-button'

export const NOTI_LAYOUT_HEADER_SALONS_BTN_ID = 'noti-layout-header-salons-btn'

export const SERVICE_EMPLOYEES_COLLAPSE_ID = 'service-employees-list'

export const EMPLOYEE_SHIFTS_CALENDAR_NEXT_WEEk_BTN_ID = 'employee-shifts-calendar-next-week-btn'

export const NOTI_LAYOUT_HEADER_SALONS_DROPDOWN = {
	SHOW_DROPDOWN: 'noti-layout-header-salons-dropdown-btn',
	DROPDOWN_CLASSNAME: 'noti-layout-header-salons-dropdown',
	SALON_BUTTON: (id: string) => `noti-layout-header-salons-dropdown-salon-${id}`
}

export const REVIEWS_BUTTON_IDS = {
	SHOW_MODERATE_DROPDOWN: (id: string) => `show-moderate-review-dropdown-btn-${id}`,
	MODERATE_DROPDOWN_CLASSNAME: (id: string) => `moderate-review-dropdown-${id}`,
	DELETE_REVIEW_BUTTON: (id: string) => `delete-review-${id}`,
	MODERATE_ACCEPT_MESSAGE: (id: string) => `moderate-review-accept-message-${id}`,
	MODERATE_PUBLISH_MESSAGE: (id: string) => `moderate-review-publish-message-${id}`,
	MODERATE_HIDE_MESSAGE: (id: string) => `moderate-review-hide-message-${id}`
}

export const AFFILIATE_WALLET_TRANSFER_TO_SMS_BTN_IDS = {
	OPEN_WALLET: 'affiliate-wallet-transfer-to-sms-open-wallet-btn',
	STEP_0_CONTINUE: 'affiliate-wallet-transfer-to-sms-step-0-continue-btn',
	STEP_1_CONTINUE: 'affiliate-wallet-transfer-to-sms-step-1-continue-btn',
	STEP_2_CLOSE: 'affiliate-wallet-transfer-to-sms-step-3-close-btn'
}

export const AFFILIATE_WALLET_TRANSFER_TO_VOUCHER_BTN_IDS = {
	OPEN_WALLET: 'affiliate-wallet-transfer-to-voucher-open-wallet-btn',
	STEP_0_CONTINUE: 'affiliate-wallet-transfer-to-voucher-step-0-continue-btn',
	STEP_1_CONTINUE: 'affiliate-wallet-transfer-to-voucher-step-1-continue-btn',
	STEP_2_SEND_TO_EMAIL: 'affiliate-wallet-transfer-to-voucher-step-2-send-to-email-btn',
	STEP_3_CONFIRM_SEND_TO_EMAIL: 'affiliate-wallet-transfer-to-voucher-step-3-confirm-send-to-email-btn',
	STEP_4_CLOSE: 'affiliate-wallet-transfer-to-voucher-step-3-close-btn'
}

export const VOUCHER_COUPON_ID = (id: string) => `voucher-coupon-${id}`

export enum PARAMETERS_VALUE_TYPES {
	TIME = 'TIME',
	ENUM = 'ENUM'
}

export enum PARAMETERS_UNIT_TYPES {
	MINUTES = 'MINUTES'
}

export const NEW_SALON_ID = 'new_salon'

export enum SALON_HISTORY_OPERATIONS {
	INSERT = 'INSERT',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE',
	RESTORE = 'RESTORE'
}

export enum SALON_HISTORY_OPERATIONS_COLORS {
	INSERT = 'success',
	UPDATE = 'warning',
	DELETE = 'danger',
	RESTORE = 'info'
}

export enum DATE_TIME_RANGE {
	LAST_DAY = 'LAST_DAY',
	LAST_TWO_DAYS = 'LAST_TWO_DAYS',
	LAST_WEEK = 'LAST_WEEK'
}

export const DEFAULT_DATE_TIME_OPTIONS = (): { [key: string]: any } => {
	return {
		[DATE_TIME_RANGE.LAST_DAY]: { name: i18next.t('loc:24 hodín'), value: 1, unit: 'day' },
		[DATE_TIME_RANGE.LAST_TWO_DAYS]: { name: i18next.t('loc:48 hodín'), value: 2, unit: 'day' },
		[DATE_TIME_RANGE.LAST_WEEK]: { name: i18next.t('loc:Týždeň'), value: 1, unit: 'week' }
	}
}

export enum OPENING_HOURS_STATES {
	CUSTOM_ORDER = 'CUSTOM_ORDER'
}

export enum SALONS_TIME_STATS_TYPE {
	BASIC = 'BASIC',
	PENDING = 'PENDING',
	PREMIUM = 'PREMIUM'
}

export enum RESERVATIONS_STATS_TYPE {
	NEW_RS_B2B = 'NEW_RS_B2B',
	NEW_RS_B2C = 'NEW_RS_B2C'
}

export enum TIME_STATS_SOURCE_TYPE {
	MONTH = 'MONTH',
	YEAR = 'YEAR'
}

export enum REVIEW_VERIFICATION_STATUS {
	NOT_VERIFIED = 'NOT_VERIFIED',
	VISIBLE_IN_B2C = 'VISIBLE_IN_B2C',
	HIDDEN_IN_B2C = 'HIDDEN_IN_B2C'
}

// CALENDAR ENUMS
export const CALENDAR_COMMON_SETTINGS = {
	LICENSE_KEY: envConfig.VITE_FULLCALENDAR_LICENSE_KEY,
	TIME_ZONE: 'local',
	TIME_FORMAT: {
		hour: '2-digit',
		minute: '2-digit',
		separator: '-',
		hour12: false
	} as FormatterInput,
	SCROLL_TIME: '08:00:00',
	SLOT_DURATION: '00:15:00',
	EVENT_MIN_DURATION: 15, // znaci, aky najuzsi event zobrazime v kalendari (v minutach) (teda ak bude mat event 10 minut, zobrazi v kalendari ako 15 minutovy - jeho realny casovy rozsah to ale neovplyvni, jedna sa len o vizualne zobrazenie)
	SLOT_LABEL_INTERVAL: '01:00:00',
	FIXED_MIRROR_PARENT: document.body,
	EVENT_CONSTRAINT: {
		startTime: '00:00',
		endTime: '23:59'
	},
	SELECT_CONSTRAINT: {
		startTime: '00:00',
		endTime: '24:00'
	}
}

export enum CALENDAR_VIEW {
	// eslint-disable-next-line @typescript-eslint/no-shadow
	DAY = 'DAY',
	WEEK = 'WEEK',
	MONTH = 'MONTH'
}

export enum CALENDAR_EVENT_TYPE {
	RESERVATION = 'RESERVATION',
	EMPLOYEE_SHIFT = 'EMPLOYEE_SHIFT',
	EMPLOYEE_TIME_OFF = 'EMPLOYEE_TIME_OFF',
	EMPLOYEE_BREAK = 'EMPLOYEE_BREAK'
}

export const RESERVATION_FROM_IMPORT = 'RESERVATION_FROM_IMPORT'

export const CALENDAR_EVENT_TYPE_REQUEST = [RESERVATION_FROM_IMPORT, ...Object.keys(CALENDAR_EVENT_TYPE)] as const

export enum CALENDAR_DATE_FORMAT {
	QUERY = 'YYYY-MM-DD',
	HEADER_DAY = 'ddd, D MMM YY',
	HEADER_WEEK_START = 'D',
	HEADER_WEEK_END = 'D MMM YY',
	HEADER_WEEK_START_TURN_OF_THE_MONTH = 'D MMM',
	HEADER_MONTH = 'MMMM YYYY',
	TIME = 'HH:mm',
	MONTH_HEADER_DAY_NAME = 'ddd',
	EVENTS_LIST_POPOVER = 'dddd, D MMM'
}

export enum CALENDAR_SET_NEW_DATE {
	NEXT_BUTTON = 'NEXT_BUTTON',
	PREV_BUTTON = 'PREV_BUTTON',
	TODAY_BUTTON = 'TODAY_BUTTON',
	DATE_PICKER = 'DATE_PICKER'
}

export enum EVERY_REPEAT {
	ONE_WEEK = 'ONE_WEEK',
	TWO_WEEKS = 'TWO_WEEKS'
}

export const EVERY_REPEAT_OPTIONS = () => [
	{
		key: EVERY_REPEAT.ONE_WEEK,
		label: i18next.t('loc:Týždeň')
	},
	{
		key: EVERY_REPEAT.TWO_WEEKS,
		label: i18next.t('loc:Druhý týždeň')
	}
]

export const EVENT_NAMES = (t: TFunction, eventType?: CALENDAR_EVENT_TYPE, capitalizeFirstLetter = false) => {
	let string = ''
	switch (eventType) {
		case CALENDAR_EVENT_TYPE.EMPLOYEE_BREAK:
			string = t('loc:prestávku')
			break
		case CALENDAR_EVENT_TYPE.EMPLOYEE_SHIFT:
			string = t('loc:shift-akuzativ')
			break
		case CALENDAR_EVENT_TYPE.RESERVATION:
			string = t('loc:rezerváciu')
			break
		case CALENDAR_EVENT_TYPE.EMPLOYEE_TIME_OFF:
			string = t('loc:voľno')
			break
		default:
			break
	}
	if (capitalizeFirstLetter && string) {
		const firstLetterCapitalized = string.charAt(0).toUpperCase()
		return firstLetterCapitalized + string.slice(1)
	}
	return string
}

export const SHORTCUT_DAYS_TRANSLATIONS = (length = 2): Record<DAY, string> => ({
	[DAY.MONDAY]: i18next.t('loc:Pondelok').substring(0, length || undefined),
	[DAY.TUESDAY]: i18next.t('loc:Utorok').substring(0, length || undefined),
	[DAY.WEDNESDAY]: i18next.t('loc:Streda').substring(0, length || undefined),
	[DAY.THURSDAY]: i18next.t('loc:Štvrtok').substring(0, length || undefined),
	[DAY.FRIDAY]: i18next.t('loc:Piatok').substring(0, length || undefined),
	[DAY.SATURDAY]: i18next.t('loc:Sobota').substring(0, length || undefined),
	[DAY.SUNDAY]: i18next.t('loc:Nedeľa').substring(0, length || undefined)
})

export enum CALENDAR_EVENTS_KEYS {
	EVENTS = 'events',
	RESERVATIONS = 'reservations',
	SHIFTS_TIME_OFFS = 'shiftsTimeOffs'
}

export enum MONTHLY_VIEW_EVENTS_CANCEL_TOKEN_KEYS {
	RESERVATIONS = 'monthlyViewReservations',
	TIME_OFFS = 'monthlyViewTimeOffs'
}

export const CALENDAR_MONTHLY_VIEW_EVENTS = 'monthlyViewEvents'

export enum CONFIRM_BULK {
	BULK = 'BULK',
	SINGLE_RECORD = 'SINGLE_RECORD'
}

export enum RESERVATION_STATE {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	DECLINED = 'DECLINED',
	CANCEL_BY_SALON = 'CANCEL_BY_SALON',
	CANCEL_BY_CUSTOMER = 'CANCEL_BY_CUSTOMER',
	CANCEL_BY_SYSTEM = 'CANCEL_BY_SYSTEM',
	REALIZED = 'REALIZED',
	NOT_REALIZED = 'NOT_REALIZED'
}

export const CALENDAR_ALLOWED_RESERVATION_STATES = [RESERVATION_STATE.APPROVED, RESERVATION_STATE.PENDING, RESERVATION_STATE.REALIZED, RESERVATION_STATE.NOT_REALIZED]

export enum RESERVATION_SOURCE_TYPE {
	ONLINE = 'ONLINE',
	OFFLINE = 'OFFLINE'
}

export enum RESERVATION_PAYMENT_METHOD {
	CASH = 'CASH',
	CARD = 'CARD',
	OTHER = 'OTHER'
}

export const CALENDAR_DEBOUNCE_DELAY = 300 // in ms
export const CALENDAR_INIT_TIME = 500 // in ms
export const CALENDAR_INIT_SCROLL_TO_TIME = 1500 // in ms

export const HANDLE_CALENDAR_FORMS = [FORM.CALENDAR_RESERVATION_FORM, FORM.CALENDAR_EVENT_FORM]

export enum HANDLE_CALENDAR_ACTIONS {
	CHANGE = 'CHANGE',
	INITIALIZE = 'INITIALIZE'
}
export const CREATE_EVENT_PERMISSIONS = [PERMISSION.PARTNER_ADMIN, PERMISSION.CALENDAR_EVENT_CREATE]
export const UPDATE_EVENT_PERMISSIONS = [PERMISSION.PARTNER_ADMIN, PERMISSION.CALENDAR_EVENT_UPDATE]
export const DELETE_EVENT_PERMISSIONS = [PERMISSION.PARTNER_ADMIN, PERMISSION.CALENDAR_EVENT_DELETE]

export const getDayNameFromNumber = (day: number) => {
	switch (day) {
		case 0: // prvy den dayjs.day() -> nedela
			return DAY.SUNDAY
		case 1:
			return DAY.MONDAY
		case 2:
			return DAY.TUESDAY
		case 3:
			return DAY.WEDNESDAY
		case 4:
			return DAY.THURSDAY
		case 5:
			return DAY.FRIDAY
		case 6:
			return DAY.SATURDAY
		default:
			return null
	}
}

/**
 * @returns localized texts for Sentry report dialog and common EN texts for result view
 */
export const ERROR_BOUNDARY_TEXTS = () => ({
	result: {
		subtitle: 'An unexpected error has occurred and your request cannot be completed. Please contact us about the error.',
		buttonLabel: 'Contact about the error'
	},
	reportDialog: {
		successMessage: i18next.t('loc:Vaša spätná väzba bola odoslaná. Ďakujeme!'),
		title: i18next.t('loc:Nastala neočakávaná chyba'),
		subtitle: i18next.t('loc:Prosím kontaktujte nás'),
		labelName: i18next.t('loc:Meno'),
		labelComments: i18next.t('loc:Popis chyby'),
		labelClose: i18next.t('loc:Zatvoriť'),
		labelSubmit: i18next.t('loc:Odoslať hlásenie o chybe')
	}
})

/**
 * Reservation system (RS)
 */
// NOTE: order definition reflect order of options in UI
export enum RS_NOTIFICATION_CUSTOMER {
	RESERVATION_AWAITING_APPROVAL_CUSTOMER = 'RESERVATION_AWAITING_APPROVAL_CUSTOMER',
	RESERVATION_CONFIRMED_CUSTOMER = 'RESERVATION_CONFIRMED_CUSTOMER',
	RESERVATION_CHANGED_CUSTOMER = 'RESERVATION_CHANGED_CUSTOMER',
	RESERVATION_CANCELLED_CUSTOMER = 'RESERVATION_CANCELLED_CUSTOMER',
	RESERVATION_REJECTED_CUSTOMER = 'RESERVATION_REJECTED_CUSTOMER',
	RESERVATION_REMINDER_CUSTOMER = 'RESERVATION_REMINDER_CUSTOMER'
	// RESERVATION_REVIEW_REQUEST_CUSTOMER = 'RESERVATION_REVIEW_REQUEST_CUSTOMER
}

// NOTE: order definition reflect order of options in UI
export enum RS_NOTIFICATION_EMPLOYEE {
	RESERVATION_AWAITING_APPROVAL_EMPLOYEE = 'RESERVATION_AWAITING_APPROVAL_EMPLOYEE',
	RESERVATION_CONFIRMED_EMPLOYEE = 'RESERVATION_CONFIRMED_EMPLOYEE',
	RESERVATION_CHANGED_EMPLOYEE = 'RESERVATION_CHANGED_EMPLOYEE',
	RESERVATION_CANCELLED_EMPLOYEE = 'RESERVATION_CANCELLED_EMPLOYEE'
}

// NOTE: order definition reflect order of options in UI
export enum RS_NOTIFICATION_CHANNEL {
	EMAIL = 'EMAIL',
	PUSH = 'PUSH',
	SMS = 'SMS'
}

export enum RS_FORWARDING_ROLES {
	PARTNER_ADMIN = 'PARTNER_ADMIN',
	PARTNER_MANAGER = 'PARTNER_MANAGER',
	PARTNER_RECEPTIONIST = 'PARTNER_RECEPTIONIST'
}

export const RS_FORWARDING_ROLES_NAMES = () => ({
	[RS_FORWARDING_ROLES.PARTNER_ADMIN]: i18next.t('loc:Admin'),
	[RS_FORWARDING_ROLES.PARTNER_MANAGER]: i18next.t('loc:Manažér'),
	[RS_FORWARDING_ROLES.PARTNER_RECEPTIONIST]: i18next.t('loc:Recepčný')
})

export const NOTIFICATION_CHANNELS = Object.keys(RS_NOTIFICATION_CHANNEL)

export const RS_NOTIFICATION_CUSTOMER_VALUES = Object.values(RS_NOTIFICATION_CUSTOMER)
export const RS_NOTIFICATION_EMPLOYEE_VALUES = Object.values(RS_NOTIFICATION_EMPLOYEE)

export const EXCLUDED_SMS_NOTIFICATIONS = [
	...RS_NOTIFICATION_EMPLOYEE_VALUES,
	RS_NOTIFICATION_CUSTOMER.RESERVATION_AWAITING_APPROVAL_CUSTOMER,
	RS_NOTIFICATION_CUSTOMER.RESERVATION_CANCELLED_CUSTOMER
]

export enum CALENDAR_DISABLED_NOTIFICATION_TYPE {
	RESERVATION_CHANGED_CUSTOMER = 'RESERVATION_CHANGED_CUSTOMER',
	RESERVATION_REJECTED_CUSTOMER = 'RESERVATION_REJECTED_CUSTOMER',
	RESERVATION_CANCELLED_CUSTOMER = 'RESERVATION_CANCELLED_CUSTOMER',
	RESERVATION_CHANGED_EMPLOYEE = 'RESERVATION_CHANGED_EMPLOYEE',
	RESERVATION_CANCELLED_EMPLOYEE = 'RESERVATION_CANCELLED_EMPLOYEE',
	RESERVATION_REJECTED_EMPLOYEE = 'RESERVATION_REJECTED_EMPLOYEE'
}

export enum CONFIRM_MODAL_DATA_TYPE {
	RESERVATION = 'RESERVATION',
	EVENT = 'EVENT',
	DELETE_EVENT = 'DELETE_EVENT',
	UPDATE_RESERVATION_STATE = 'UPDATE_RESERVATION_STATE'
}

// https://fullcalendar.io/docs/eventDisplay
export enum CALENDAR_EVENT_DISPLAY_TYPE {
	AUTO = 'auto',
	BLOCK = 'block',
	LIST_ITEM = 'list-item',
	BACKGROUND = 'background',
	INVERSE_BACKGROUND = 'inverse-background',
	NONE = 'none'
}

export const CALENDAR_DAY_EVENTS_SHOWN = 5
export const CALENDAR_DAY_EVENTS_LIMIT = CALENDAR_DAY_EVENTS_SHOWN + 1
export const RESERVATION_STATES = Object.keys(RESERVATION_STATE)
export const RESERVATION_PAYMENT_METHODS = Object.keys(RESERVATION_PAYMENT_METHOD)
export const RESERVATION_SOURCE_TYPES = Object.keys(RESERVATION_SOURCE_TYPE)

export const CALENDAR_UPDATE_SIZE_DELAY_AFTER_SIDER_CHANGE = 300 // in ms
export const CALENDAR_UPDATE_SIZE_DELAY = 100 // in ms

export enum CANCEL_TOKEN_MESSAGES {
	CANCELED_DUE_TO_NEW_REQUEST = 'Operation canceled due to new request.',
	CANCELED_ON_DEMAND = 'Operation canceled.'
}

export enum SMS_NOTIFICATION_EVENT_TYPE {
	RESERVATION_CONFIRMED_CUSTOMER = 'RESERVATION_CONFIRMED_CUSTOMER',
	RESERVATION_CHANGED_CUSTOMER = 'RESERVATION_CHANGED_CUSTOMER',
	RESERVATION_REJECTED_CUSTOMER = 'RESERVATION_REJECTED_CUSTOMER',
	RESERVATION_REMINDER_CUSTOMER = 'RESERVATION_REMINDER_CUSTOMER'
}

export enum SMS_NOTIFICATION_STATUS {
	SUCCESS = 'SUCCESS',
	FAILURE = 'FAILURE',
	IGNORED = 'IGNORED'
}

export const SMS_NOTIFICATION_EVENT_TYPE_NAME = (notificationType: SMS_NOTIFICATION_EVENT_TYPE) => {
	switch (notificationType) {
		case SMS_NOTIFICATION_EVENT_TYPE.RESERVATION_CONFIRMED_CUSTOMER:
			return i18next.t('loc:Potvrdenie')
		case SMS_NOTIFICATION_EVENT_TYPE.RESERVATION_CHANGED_CUSTOMER:
			return i18next.t('loc:Zmena')
		case SMS_NOTIFICATION_EVENT_TYPE.RESERVATION_REJECTED_CUSTOMER:
			return i18next.t('loc:Zrušenie')
		case SMS_NOTIFICATION_EVENT_TYPE.RESERVATION_REMINDER_CUSTOMER:
			return i18next.t('loc:Pripomienka')
		default:
			return ''
	}
}

export const SMS_STATUS_NAME = (status: SMS_NOTIFICATION_STATUS) => {
	switch (status) {
		case SMS_NOTIFICATION_STATUS.SUCCESS:
			return i18next.t('loc:Prijatá')
		case SMS_NOTIFICATION_STATUS.FAILURE:
			return i18next.t('loc:Nedoručená')
		case SMS_NOTIFICATION_STATUS.IGNORED:
			return i18next.t('loc:Ignorovaná')
		default:
			return ''
	}
}

export enum EXTERNAL_CALENDAR_TYPE {
	MICROSOFT = 'MICROSOFT',
	GOOGLE = 'GOOGLE'
}

export const EXTERNAL_CALENDAR_CONFIG = {
	[EXTERNAL_CALENDAR_TYPE.MICROSOFT]: {
		redirect_uri: `${window.location.protocol}//${window.location.host}/ms-oauth2`,
		scope: 'offline_access user.read Calendars.ReadWrite Calendars.Read',
		authorize_url: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
		response_mode: 'query',
		response_type: 'code',
		prompt: 'select_account'
	},
	[EXTERNAL_CALENDAR_TYPE.GOOGLE]: {
		flow: 'auth-code',
		scope: 'email profile https://www.googleapis.com/auth/calendar openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
		redirect_uri: 'postmessage'
	} as UseGoogleLoginOptionsAuthCodeFlow
}

export const SERVICES_LIST_INIT = {
	industries: {
		data: [],
		servicesCount: 0,
		servicesAvailableForOnlineReservationsCount: 0,
		servicesVisibleInPricelistCount: 0
	}
}

export enum SERVICE_DESCRIPTION_LNG {
	DEFAULT = 'DEFAULT',
	EN = 'en'
}

export const MS_REDIRECT_MESSAGE_KEY = 'MS_REDIRECT_MESSAGE_KEY'

export enum ALL_LANGUAGES {
	ALL_LANGUAGES = 'ALL_LANGUAGES',
	SELECTED_LANGUAGES = 'SELECTED_LANGUAGES'
}

export enum NOTIFICATION_EVENT_GROUP_TYPE {
	RESERVATION = 'RESERVATION',
	OTHER = 'OTHER'
}

export enum NOTIFICATION_EVENT_TYPE {
	RESERVATION_CONFIRMED_EMPLOYEE = 'RESERVATION_CONFIRMED_EMPLOYEE',
	RESERVATION_CHANGED_EMPLOYEE = 'RESERVATION_CHANGED_EMPLOYEE',
	RESERVATION_AWAITING_APPROVAL_EMPLOYEE = 'RESERVATION_AWAITING_APPROVAL_EMPLOYEE',
	RESERVATION_CANCELLED_EMPLOYEE = 'RESERVATION_CANCELLED_EMPLOYEE',
	DYNAMIC_NOTIFICATION = 'DYNAMIC_NOTIFICATION',
	UNRESOLVED_RESERVATIONS = 'UNRESOLVED_RESERVATIONS'
}

export enum AFFILIATE_PROGRAM_TRANSACTION_TYPE {
	SMS = 'SMS',
	BANK_ACCOUNT = 'BANK_ACCOUNT',
	VOUCHER = 'VOUCHER'
}

export enum TRANSACTION_ACTION {
	AFFILIATE_WALLET_TO_SMS_WALLET = 'AFFILIATE_WALLET_TO_SMS_WALLET',
	AFFILIATE_WALLET_TO_VOUCHER = 'AFFILIATE_WALLET_TO_VOUCHER'
}

export enum WALLET_TYPE {
	SMS_WALLET = 'SMS_WALLET',
	AFFILIATE_WALLET = 'AFFILIATE_WALLET'
}

export const MAX_RESERVATIONS_REPORT_DATE_INTERVAL = 365 // in days

export enum SET_OF_COUNTRIES {
	ALL = 'ALL',
	ROLLOUT = 'ROLLOUT'
}

export enum RESERVATIONS_DATE_TIME_FRAME {
	UPCOMING = 'UPCOMING',
	PAST = 'PAST'
}

export enum COOKIE_CATEGORY {
	NECESSARY = 'necessary',
	FUNCTIONAL = 'functional',
	ANALYTICS = 'analytics',
	MARKETING = 'marketing'
}

export enum CHANGELOG_PLATFORM {
	ADMIN = 'ADMIN',
	B2C = 'B2C',
	B2B = 'B2B'
}

export enum CHANGELOG_STATUS {
	DRAFT = 'DRAFT',
	PUBLISHED = 'PUBLISHED'
}

export const CHANGELOG_MAIN_FEATURES_MAX_COUNT = 3

export enum EMPLOYEE_WORKING_HOURS_TYPE {
	BY_DAY = 'BY_DAY',
	BY_WORKING_SHIFTS = 'BY_WORKING_SHIFTS'
}

export enum VOUCHER_STATUS {
	ACTIVE = 'ACTIVE',
	USED = 'USED',
	INVALID = 'INVALID',
	ALL = 'ALL'
}

export enum VOUCHER_TYPE {
	AFFILIATE = 'AFFILIATE'
}

export const VOUCHER_TRANSLATIONS = (): Record<VOUCHER_TYPE, string> => ({
	[VOUCHER_TYPE.AFFILIATE]: i18next.t('loc:Affiliate kupón')
})

export enum AFFIL_BONUS {
	VALID = 'VALID',
	NOT_VALID = 'NON_VALID',
	NOT_APPLICABLE = 'NOT_APPLICABLE'
}

export const MAIN_LAYOUT_CONTENT_CLASSES = {
	DEFAULT: 'main-default main-background',
	CALENDAR: 'main-calendar',
	DASHBOARD: 'dashboard-wrapper main-background'
}

export const WITHOUT_ASSIGNED_USER = 'WITHOUT_ASSIGNED_USER'

export enum SALON_DETAIL_FORM_SECTION {
	BASIC_INFO_SECTION = 'basic_info_section',
	CONTACT_INFO_SECTION = 'contact_info_section',
	ADDRESS_SECTION = 'address_section',
	OPENING_HOURS_SECTION = 'opening_hours_section',
	PAYMENT_OPTIONS_SECTION = 'payment_options_section',
	SOCIAL_NETWORKS_SECTION = 'social_networks_section'
}

export enum BILLING_INFO_FORM_SECTION {
	CONTACT_PERSON_SECTION = 'contact_person_section',
	BANK_DETAILS_SECTION = 'bank_details_section',
	CORPORATE_INFORMATION_SECTION = 'corporate_information_section',
	BILLING_ADDRESS_SECTION = 'billing_address_section'
}

export enum SERVICE_TYPE {
	NORMAL = 'NORMAL',
	CUSTOM = 'CUSTOM'
}

export enum DYNAMIC_NOTIFICATIONS_URL_TYPE {
	GENERAL = 'GENERAL'
}

export enum DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE {
	OTHER = 'OTHER',
	DOCUMENT = 'DOCUMENT'
}

export enum DOCUMENT_ASSET_TYPE_PREFIX {
	B2B = 'B2B',
	B2C = 'B2C'
}

export enum NOTIFICATION_URL_RESOLVER_RESPONSE_TYPE {
	RESERVATION_DETAIL = 'RESERVATION_DETAIL',
	DOCUMENT_DETAIL = 'DOCUMENT_DETAIL',
	OPENED_RESERVATIONS = 'OPENED_RESERVATIONS'
}

export const REGULAR_WORKING_HOURS_MAX_TIME_RANGES_COUNT = 3

export enum ASYNC_JOB_TYPE {
	EXPORT_RESERVATIONS = 'EXPORT_RESERVATIONS',
	EXPORT_SALON_RESERVATIONS = 'EXPORT_SALON_RESERVATIONS',
	EXPORT_SALONS_REPORT = 'EXPORT_SALONS_REPORT',
	EXPORT_CUSTOM_SERVICES = 'EXPORT_CUSTOM_SERVICES',
	IMPORT_CALENDAR_EVENTS = 'IMPORT_CALENDAR_EVENTS',
	IMPORT_CUSTOMERS = 'IMPORT_CUSTOMERS',
	IMPORT_SALONS = 'IMPORT_SALONS'
}

export enum ASYNC_JOB_STATE {
	COMPLETED = 'COMPLETED',
	IN_PROGRESS = 'IN_PROGRESS',
	FAILED = 'FAILED',
	COULD_NOT_VERIFY = 'COULD_NOT_VERIFY'
}

export enum ONBOARDING_TOOLTIP_TYPE {
	DEFAULT = 'DEFAULT',
	POINTING = 'POINTING',
	CENTRAL = 'CENTRAL'
}

export enum DEFAULT_ONBOARDING_TOOLTIP_POSITION {
	TOP_RIGHT_CORNER = 'TOP_RIGHT_CORNER'
}

export enum ONBOARDING_TOOLTIP_GROUP {
	CALENDAR = 'calendar',
	SERVICE_DETAIL = 'service_detail',
	EMPLOYEE_DETAIL = 'employee_detail'
}

export enum ONBOARDING_TOOLTIP_ID {
	CALENDAR_SERVICES_COLORS = 'calendar_services_colors',
	SERVICE_DETAIL_COLOR = 'service_detail_color',
	EMPLOYEE_DETAIL_COLOR = 'employee_detail_color'
}

export enum SALON_RESERVATIONS_ORDER {
	START_DATE_ASC = 'startDate:ASC',
	START_DATE_DESC = 'startDate:DESC',
	CREATED_AT_ASC = 'createdAt:ASC',
	CREATED_AT_DESC = 'createdAt:DESC'
}

export enum ADMIN_RESERVATIONS_ORDER {
	START_DATE_ASC = 'startDate:ASC',
	START_DATE_DESC = 'startDate:DESC',
	CREATED_AT_ASC = 'createdAt:ASC',
	CREATED_AT_DESC = 'createdAt:DESC'
}

export enum CUSTOM_SERVICES_ORDER {
	CREATED_AT_ASC = 'createdAt:ASC',
	CREATED_AT_DESC = 'createdAt:DESC'
}

export enum SALONS_ORDER {
	CREATED_AT_ASC = 'createdAt:ASC',
	CREATED_AT_DESC = 'createdAt:DESC',
	NAME_ASC = 'name:ASC',
	NAME_DESC = 'name:DESC',
	FILLING_PROGRESS_ASC = 'fillingProgress:ASC',
	FILLING_PROGRESS_DESC = 'fillingProgress:DESC'
}

export enum RESERVATION_QUICK_FEEDBACK_ANSWER {
	EASY_PROCESS = 'EASY_PROCESS',
	QUICK_PROCESS = 'QUICK_PROCESS',
	INTUITIVE = 'INTUITIVE',
	BEAUTIFUL_DESIGN = 'BEAUTIFUL_DESIGN',
	OTHER = 'OTHER',
	NON_INTUITIVE = 'NON_INTUITIVE',
	COMPLICATE = 'COMPLICATE',
	MISSING_INFORMATION = 'MISSING_INFORMATION',
	GOT_STUCK = 'GOT_STUCK'
}

export enum CONTACT_PAGE_COLLAPSE_KEY {
	CONTACT_INFO = 'CONTACT_INFO',
	OPENING_HOURS = 'OPENING_HOURS'
}
